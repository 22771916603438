import Cookie from '@/service/cookie'
import axios from 'axios'
import store from '../store'
import Vue from 'vue'

export default {

  // todas as rotas privadas passam por aqui (beforeEnter: Guard.redirectIfNotAuthenticated,)
  async redirectIfNotAuthenticated (to, from, next) {
    const token = Cookie.getToken();
    if(!token)
    {
      Cookie.deleteToken();
      next({ name: 'Auth' });
    }
    else
    {
      axios
        .get('me')
        .then((response) => { 
          store.commit('storeUser', response.data.user);
        })
        .catch(e => {
            Cookie.deleteToken();
            next({ name: 'Auth' });
        });
    }
    next()
  },

  redirectIfAuthenticated (to, from, next) {
    const token = Cookie.getToken()

    if (token) {
      next({ name: 'Home' })
    }

    next()
  }
}
