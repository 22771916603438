<template>
  <bui-page>
    <template #page-header-left>
      <h1 class="bui-page-title">
        {{ $t('be-title-prices') }}
      </h1>
    </template>
    <template #page-header-right>
      <b-input type="search" :placeholder="$t('ge-search')" class="mx-2" v-model="search" @keyup.enter="searchCountry()" @input="searchOnkeypress()" style="max-width: 200px"/>
    </template>
    <builderall-loader :loading="showTable == false">
      <div>
        <b-table class="bg-white" stacked="md" hover :items="items" :fields="fields" :per-page="perPage" :current-page="currentPage"></b-table>
        <b-pagination v-if="items.length > 9" v-model="currentPage" :total-rows="rows" pills :per-page="perPage" aria-controls="my-table" align="right"></b-pagination>
      </div>
    </builderall-loader>
  </bui-page>
</template>

<script>
  export default {
    data() {
      return {
        search: null,
        showTable: false,
        perPage: 9,
        currentPage: 1,
        fields: [
          {key: 'description', label: this.$t('be-description-prices'), sortable: true},
          {key: 'code_iso', label: this.$t('be-code-prices')},
          {key: 'code_number', label: this.$t('be-code-number')},
          {key: 'unit_price', label: this.$t('be-price-prices'), sortable: true}
        ],
        items: [],
        searchTimer: null
      }
    },
    methods: {
      searchOnkeypress(){
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(this.searchCountry, 1000);
      },
      searchCountry() {
        this.showTable = false;
        this.currentPage = 1;
        this.$axios
          .get('country_price?query=' + this.search)
          .then((response) => {
            if(response.data.data.length > 0)
            {
              if(response.status == 200)
              {
                this.items = response.data.data
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-search-country'));
              }
            }
            else
            {
              this.toast('danger',this.$t('be-t-error-search'));
            }
            this.showTable = true;
          })
          .catch(e => {this.showTable = true; this.toast('danger',this.$t('be-t-error-search-country'))});
      },
      toast(variant,text) {
        this.$bvToast.toast(text, {
          variant: variant
        })
      }
    },
    computed: {
      rows() {
        return this.items.length
      }
    },
    watch: {
      search(newValue, oldValue) {
        if(newValue == '')
        {
          clearTimeout(this.searchTimer);
          this.searchCountry();
        }
      }
    },
    mounted() {
      this.$axios
          .get('country_price')
          .then((response) => {this.showTable = true; this.items = response.data.data})
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-country')));
    }
  }
</script>