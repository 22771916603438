import axios from 'axios'
import Vue from 'vue'
import Cookie from '@/service/cookie'

// API
const prodUrl = 'https://sms-api.kub.app.builderall.com/api/v1/';
const homologUrl = 'https://sms-api.kub.app.builderall.info/api/v1/';
const testUrl = 'https://sms-api.kub.app.builderall.io/api/v1/';


// APPSHELL
const testUrlAppshell = 'https://appshell.kub.app.builderall.io/appshell.js';
const homologUrlAppshell = 'https://appshell.kub.app.builderall.info/appshell.js';
const prodUrlAppshell = 'https://js.builderall.com/builderall.js';

// BUILDERALL.CSS
const testUrlCss = 'https://css.kub.app.builderall.io/builderall.css';
const homologUrlCss = 'https://css.kub.app.builderall.info/builderall.css';
const prodUrlCss = 'https://css.builderall.com/builderall.css';

let url = document.URL;
let isProduction = url.indexOf('sms-front.kub.app.builderall.com') != -1;
let isHomolog = url.indexOf('sms-front.kub.app.builderall.info') != -1;
let isTest = url.indexOf('sms-front.kub.app.builderall.io') != -1;
let isLocal = url.indexOf('localhost') != -1;
console.log('axios.js - URL: ' + url);
console.log('isTest: ' + isTest);
let baseUrl;
let appshellUrl;
let cssUrl;

if(isHomolog)
{
  // use prodUrl in homolog provisory
  baseUrl = homologUrl;
  appshellUrl = homologUrlAppshell;
  cssUrl = homologUrlCss;
}
else if(isTest)
{
  baseUrl = testUrl;
  appshellUrl = testUrlAppshell;
  cssUrl = testUrlCss;
}
else if(isLocal)
{
  baseUrl = prodUrl;
  appshellUrl = testUrlAppshell;
  cssUrl = testUrlCss;
}
else
{
  baseUrl = prodUrl;
  appshellUrl = prodUrlAppshell;
  cssUrl = prodUrlCss;
}

const script = document.createElement('script');
script.src = appshellUrl;
document.head.appendChild(script);

const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = cssUrl;
document.head.appendChild(link);

axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use((config) => {
  const token = Cookie.getToken()
  if (token) {
    config.headers.common.Authorization = token
  }
  return config
})

Vue.prototype.$axios = axios
