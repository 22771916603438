<template>
  <bui-page>
    <template #page-header-left v-if="sendSmsTemplate">
      <h1 class="bui-page-title">
        {{ $t('be-name-app') }}
      </h1>
    </template>
      <template #page-header-right v-if="sendSmsTemplate">
        <builderall-loader :loading="loadBalance">
        <div v-if="loadBalance == 'nan'">
          <h6>{{ $t('be-connect-twilio-account') }} <b-link to="/tutorials" class="b"><b>{{ $t('be-how-to-do') }}</b></b-link></h6>
        </div>
        <div v-else>
          <h6 v-if="hasErrorOnRegister">{{ $t('be-connect-twilio-correctly') }} <b-link to="/data-user" class="b"><b>{{ $t('ge-here') }}</b></b-link></h6>
          <h6 v-else-if="!exists">{{ $t('be-connect-twilio-account') }} <b-link to="/tutorials" class="b"><b>{{ $t('ge-here') }}</b></b-link></h6>
          <h6 v-else-if="balance <= 0">{{ $t('be-message-buy') }} <b-link to="/tutorials" class="b"><b>{{ $t('ge-buy-here') }}</b></b-link></h6>
          <h6 v-else>{{ $t('be-legend-balance-lead') }}: <b class="p-2 ml-2 rounded bg-white text-primary h3" v-html="'USD $ '+parseFloat(balance).toFixed(2)"></b></h6>
        </div>
      </builderall-loader>
      </template>
      <!-- ALERT -->
      <AlertA2P v-if="showA2PAlert" />
      <!-- STEPS -->
      <builderall-loader :loading="sendSmsTemplate == false">
        <br>
        <div class="card-style">
          <b-progress :value="progress" class="progress-line" height="8px" variant="success"></b-progress>
        </div>
        <b-card v-if="current_step==1" class="card-style">
          <h3 class="text-success">{{ $t('be-steps') }} 1/{{ max_step }}</h3>
          <hr>
          <bui-form-group :label="$t('be-message')" :help="$t('be-help-message-submit')" required>
            <!-- add readonly -->
            <b-form-textarea maxlength="160" required :placeholder="$t('be-placeholder-message')" v-model="message" :state="stateMessage" rows="5"/>
            <p>{{ this.message.length + '/160' }}</p>
          </bui-form-group>
          <p v-if="message.length > 4" class="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="text-danger bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            <strong class="ml-2">{{ $t('be-attention-write-message') }}</strong>
          </p>
          <!-- add disabled and remove @click="checkMessage()" -->
          <b-button class="float-right" @click="checkMessage()" variant="primary" :title="$t('ge-next')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
              <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </b-button>
        </b-card>
        <b-card v-if="current_step==2" class="card-style">
          <h3 class="text-success">{{ $t('be-steps') }} 2/{{ max_step }}</h3>
          <hr>
          <div>
            <bui-form-group :label="$t('be-what-recipients')">
              <b-container class="bv-example-row">
                <b-row>
                  <b-col class="text-center">
                    <b-form-radio-group v-model="typeRecipient" :disabled="this.peopleSelected.length > 0 ? true : false" :options="[{text: $t('be-categories'), value: 'category'}]" v-b-popover.hover.top="this.peopleSelected.length > 0 ? $t('be-has-people') : $t('be-choose-category')" class="bui-radiogroup-box mt-4"/>
                  </b-col>
                  <b-col class="text-center">
                    <b-form-radio-group v-model="typeRecipient" :disabled="this.categorySelected.length > 0 ? true : false" :options="[{text: $t('be-peoples'), value: 'person'}]" v-b-popover.hover.top="this.categorySelected.length > 0 ? $t('be-has-category') : $t('be-choose-person')" class="bui-radiogroup-box mt-4"/>
                  </b-col>
                </b-row>
              </b-container>
            </bui-form-group>
          </div>
          <br>
          <b-button class="float-left" variant="none" @click="onClickBack" :title="$t('ge-back')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left text-primary" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </b-button>
          <b-button class="float-right" variant="primary" @click="checkTypeRecipient()" :title="$t('ge-next')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
              <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </b-button>
        </b-card>
        <b-card v-if="current_step==3" class="card-style">
          <h3 class="text-success">{{ $t('be-steps') }} 3/{{ max_step }}</h3>
          <hr>
          <div v-if="typeRecipient == 'category'">
            <b-container class="bv-example-row">
              <b-row class="box-recipients">
                <b-col>
                  <p>{{ $t('be-select-category') }}:</p>
                </b-col>
                <b-col>
                  <p class="text-center text-success">{{ $t('be-selected-category') }}: {{ categorySelected.length }}</p>
                </b-col>
                <b-col>
                  <b-input type="search" v-b-popover.hover.bottom="$t('be-enter-to-search')" :placeholder="$t('ge-search')" class="mx-5" v-model="searchCategory" @keyup.enter="searchRegister(searchCategory, 0)" style="max-width: 200px"/>
                </b-col>
              </b-row>
            </b-container>
            <builderall-loader :loading="showTableCategory == false">
              <div id="tableCategory">
                <b-table class="bg-white text-center" show-empty :empty-text="$t('ge-no-records-to-show')" hover :items="categories" :fields="fieldsCategory" :per-page="perPageCategory" :current-page="currentPageCategory">
                  <template #cell(actions)="row">
                    <bui-form-group class="d-flex justify-content-center">
                      <b-form-checkbox :disabled="row.item.qtt_persons == 0" v-b-popover.hover.bottom="row.item.qtt_persons == 0 ? $t('be-category-not-have-people') : ''" v-model="categorySelected" :value="row.item" switch :checked="false" class="bui-switch-success"></b-form-checkbox>
                    </bui-form-group>
                  </template>
                </b-table>
              </div>
              <b-pagination v-if="rowsCategory > 6" v-model="currentPageCategory" :total-rows="rowsCategory" pills :per-page="perPageCategory" aria-controls="my-table" align="right"></b-pagination>
            </builderall-loader>
          </div>
          <div v-if="typeRecipient == 'person'">
            <b-container class="bv-example-row">
              <b-row class="box-recipients">
                <b-col>
                  <p>{{ $t('be-select-people') }}:</p>
                </b-col>
                <b-col>
                  <p class="text-center text-success">{{ $t('be-selected-people') }}: {{ peopleSelected.length }}</p>
                </b-col>
                <b-col>
                  <b-input type="search" v-b-popover.hover.bottom="$t('be-enter-to-search')" :placeholder="$t('ge-search')" class="mx-5" v-model="searchPerson" @keyup.enter="searchRegister(searchPerson, 1)" style="max-width: 200px"/>
                </b-col>
              </b-row>
            </b-container>
            <builderall-loader :loading="showTablePerson == false">
              <div id="tablePeople">
                <b-table class="bg-white text-center" show-empty :empty-text="$t('ge-no-records-to-show')" hover :items="peoples" :fields="fieldsPeople" :per-page="perPagePerson" :current-page="currentPagePerson">
                  <template #cell(actions)="row">
                    <bui-form-group>
                      <b-form-checkbox :disabled="categorySelected.length > 0 ? true : false" v-model="peopleSelected" :value="row.item" switch :checked="false" class="bui-switch-success"></b-form-checkbox>
                    </bui-form-group>
                  </template>
                </b-table>
              </div>
              <b-pagination v-if="rowsPerson > 6" v-model="currentPagePerson" :total-rows="rowsPerson" pills :per-page="perPagePerson" aria-controls="my-table" align="right"></b-pagination>
            </builderall-loader>
          </div>
          <br>
          <b-button class="float-left" variant="none" @click="onClickBack" :title="$t('ge-back')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left text-primary" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </b-button>
          <b-button class="float-right" variant="primary" @click="checkSelected()" :title="$t('ge-next')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
              <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </b-button>
        </b-card>
        <b-card v-if="current_step==4" class="card-style">
          <h3 class="text-success">{{ $t('be-steps') }} 4/{{ max_step }}</h3>
          <hr>
          <h5>SMS:</h5>
          <p class="text-center h5 text-warning">{{ message }}</p>
          <hr>
          <div class="row ml-5">
            <div class="col-sm-6">
              <h5>{{ $t('be-categories') }}({{ categorySelected.length }}):</h5>
              <ul class="ml-5" v-for="(category,i) in categorySelected" :key="i">
                <li>
                  {{ category['description'] }} 
                  <svg xmlns="http://www.w3.org/2000/svg" @click="modalShowCategory(category)" width="16" height="16" fill="currentColor" class="bi bi-eye-fill text-success" viewBox="0 0 16 16">
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                  </svg>
                </li>
              </ul>
            </div>
            <div class="col-sm-6">
              <h5>{{ $t('be-peoples') }}({{ peopleSelected.length }}):</h5>
              <ul class="ml-5" v-for="(people,i) in peopleSelected" :key="i">
                <li>{{ people['name'] }}</li>
              </ul>
            </div>
          </div>
          <b-button class="float-left" variant="none" @click="onClickBack" :title="$t('ge-back')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left text-primary" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
          </b-button>
          <b-button class="float-right" variant="success" @click="openModalSubmit = true" :title="$t('ge-confirm')">{{ $t('ge-confirm') }}</b-button>
        </b-card>
      </builderall-loader>
      <!-- Modal show peoples/category -->
      <b-modal v-model="openModalCategory" :title="$t('be-category') + ': ' + nameCategory" centered no-fade hide-footer>
        <ul class="ml-5">
          <li v-for="(person,i) in peoplesCategory" :key="i"><b>{{ person.name }}</b></li>
        </ul>
      </b-modal>
      <!-- Modal submit sms -->
      <b-modal v-model="openModalSubmit" centered no-fade ok-variant="success" :ok-title="$t('ge-confirm')" :cancel-title="$t('ge-cancel')" @ok="submitSms()">
        <div slot="modal-title">
          <bui-icon name="circle-incomplete" variant="primary" size="15"></bui-icon>
          {{ this.$t('be-details-submit') }}
        </div>
        <ul class="ml-5">
          <li><b>{{ this.$t('ge-value') }}:</b><b class="text-success h5"> USD $ {{ parseFloat(this.valueTotal).toFixed(3) }}</b></li>
          <li v-if="this.peopleSelected.length > 0"><b>{{ this.$t('be-peoples') }}: {{ this.peopleSelected.length }}</b></li>
          <li v-if="this.categorySelected.length > 0"><b>{{ this.$t('be-categories') }}:</b> {{ this.categorySelected.length }}</li>
        </ul>
      </b-modal>
      <!-- Modal progress confirm -->
      <b-modal v-model="openModalProgress" centered no-fade hide-footer @hide="reset(), balanceUser()">
        <div slot="modal-title" class="text-success">
          {{ this.$t('ge-success') }}!
        </div>
        <div class="text-center">
          <bui-icon name="circle-complete" size="80"></bui-icon>
          <hr>
          <h5 class="text-success">{{this.$t('be-submit-to')+' '+this.qtdRecipients+' '+this.$t('be-person-s')}}</h5>
        </div>
      </b-modal>
      <b-modal v-model="openModalError" centered no-fade hide-footer @hide="reset(), balanceUser()">
        <div slot="modal-title" class="text-danger">
          {{ this.$t('be-t-error-submit-sms') }}!
        </div>
        <div class="text-center">
          <bui-icon name="circle-incomplete" variant="danger" size="80"></bui-icon>
          <hr>
          <h5 class="text-danger"><b v-html="errorMessage"></b></h5>
          <h6 class="text-danger"><u>{{ this.$t('be-try-again') }}!</u></h6>
        </div>
      </b-modal>
  </bui-page>
</template>
<script>
  import AlertA2P from  '../components/AlertA2P' 
  export default {
    components: {
      AlertA2P
    },
    data() {
      return {  
        errorMessage: "",
        balance: 0,
        loadBalance: false,
        exists: false,
        openModalError: false,
        showTablePerson: true,
        showTableCategory: true,
        currentPageCategory: 1,
        currentPagePerson: 1,
        perPageCategory: 7,
        perPagePerson: 7,
        searchCategory: "",
        searchPerson: "",
        current_step: 1,
        max_step: 4,
        openModalSubmit: false,
        openModalCategory: false,
        openModalProgress: false,
        selectRecipient: true,
        sendSmsTemplate: true,
        loadingSmsTemplate: false,
        message: '',
        qtdRecipients: 0,
        typeRecipient: null,
        nameCategory: null,
        valueTotal: null,
        hasErrorOnRegister: false,
        peoplesCategory: [],
        categorySelected: [],
        categorySelectedId: [],
        categories: [],
        peopleSelected: [],
        peopleSelectedId: [],
        fieldsCategory: [
          {key: 'description', label: this.$t('be-category'), sortable: true},
          {key: 'qtt_persons', label: this.$t('be-peoples'), sortable: true},
          {key: 'actions', label: ''},
        ],
        fieldsPeople: [
          {key: 'name', label: this.$t('be-name'), sortable: true},
          {key: 'email', label: this.$t('be-modal-email-person')},
          {key: 'country_price.description', label: this.$t('be-modal-country-person')},
          {key: 'phone_number', label: this.$t('be-modal-phone-person')},
          {key: 'actions', label: ''}
        ],
        peoples: [],
        hasA2P: false,
        showA2PAlert: false,
      }
    },
    created(){
      this.getA2PStatus();
    },
    methods: {
      getA2PStatus(){
        const email = localStorage.getItem('smsuseremail')
        this.$axios
          .post('verify-a2p10dlc', {
              email: email,
              origin: 'dashboard'
          })
          .then((response) => {
              this.hasA2P = true;
          })
          .catch(e => {
              this.hasA2P = false;
          }) 
      },
      onClickNext() {
        this.current_step++;
        this.progressUpdateStyle();
      },
      onClickBack() {
        this.current_step--;
        this.progressUpdateStyle();
      },
      onClickFirst() {
        this.current_step = 1;
      },
      checkTypeRecipient() {
        if(!this.typeRecipient)
        {
            this.toast('warning',this.$t('be-t-select-type-recipient'));
        }
        else
        {
            this.onClickNext();
        }
      },
      getData(){
        this.sendSmsTemplate = false;
        this.loadingSmsTemplate = true;
        this.$axios
          .get('twilio_user-by-user')
          .then((response) => {
            if(response.status == 200)
            {
              this.exists = true;
              this.balanceUser();
            }
            else
            {
              this.exists = false;
            }
          })
          .catch(e => {})
          .finally(() => {
            this.sendSmsTemplate = true;
            this.loadingSmsTemplate = false;
            setTimeout(() => {
              this.checkMessage(1);
            },1500);
          });
      },
      checkMessage(data = 0) {
        if(this.exists)
        {
          if(!this.hasErrorOnRegister)
          {
            if(this.balance <= 0)
            {
              this.toast('warning',this.$t('be-message-buy'));
            }
            else 
            {
              if(!this.message || this.message.length > 160)
              {
                this.toast('warning',this.$t('be-t-write-message'));
              }
              else if(this.message.length < 5)
              {
                this.toast('warning',this.$t('be-t-write-valid-message'));
              }
              else
              {
                if(data == 0)
                {
                  this.onClickNext();
                }
              }
            }
          }
          else
          {
            this.toast('warning',this.$t('be-not-connect-correctly-twilio-account'));
          }
        }
        else
        {
          this.toast('warning',this.$t('be-you-not-connect-twilio-account'));
        }
      },
      checkSelected() {
        if(this.peopleSelected == '' && this.categorySelected == '')
        {
          this.toast('warning',this.$t('be-t-select-recipients'));
        }
        else if(this.peopleSelected.length > 0 && this.categorySelected.length > 0)
        {
          this.toast('warning',$t('be-t-simultaneous-recipients'));
        }
        else
        {
          this.categorySelectedId = [];
          this.peopleSelectedId = [];
          this.valueTotal = null;
          if(this.categorySelected.length > 0)
          {
            for(var c = 0; c < this.categorySelected.length; c++)
            {
              this.categorySelectedId[c] = this.categorySelected[c]['id'];
            }
          }
          else
          {
            let hasEuaCanada = false;

            for(var i = 0; i < this.peopleSelected.length; i++)
            {
              this.peopleSelectedId[i] = this.peopleSelected[i]['id'];
              const codeIso = this.peopleSelected[i]['country_price']['code_iso'];
              if((codeIso == 'US' || codeIso == 'CA'))
              {
                hasEuaCanada = true;
              }
            }

            if(hasEuaCanada && !this.hasA2P)
            {
              this.showA2PAlert = true;
              this.toast('danger', this.$t('be-to-send-sms-you-need-a2p'));
              return;
            }
            else
            {
              this.showA2PAlert = false;
            }
          }
          this.$axios
            .post("send-message-dashboard", {
              message: this.message,
              categories: this.categorySelectedId.length > 0 ? this.categorySelectedId : '',
              persons: this.peopleSelectedId,
              action: 'check_credit'
            })
            .then((response) => { 
              if(response.status == 200)
              {
                this.valueTotal = response.data.value;
                this.onClickNext();
              }
            })
            .catch(e => this.toast('danger',this.$t('be-t-error-value')));
        }
      },
      searchRegister(data,type) {
        // type = 0 -> category
        // type = 1 -> person
        let typeUrl = "person";
        if(type == 0)
        {
          typeUrl = "category";
          this.showTableCategory = false;
        }
        else
        {
          this.showTablePerson = false;
        }
        this.$axios
          .get(typeUrl+'?query=' + data)
          .then((response) => {
              if(response.status == 200)
              {
                if(type == 0)
                {
                  this.categories = response.data.data;
                  this.currentPageCategory = 1;
                  this.showTableCategory = true;
                }
                else
                {
                  this.peoples = response.data.data;
                  this.currentPagePerson = 1;
                  this.showTablePerson = true;
                }
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-search-item'));
              }
          })
          .catch(e => {this.toast('danger',this.$t('be-t-error-search-item'));});
      },
      toast(variant,text) {
        this.$bvToast.toast(text, {
          variant: variant
        })
      },
      progressUpdateStyle() {
        var lineProgress = document.querySelector('.progress-line');
        lineProgress.style.width = `${this.progress}%`
      },
      modalShowCategory(data) {
        this.$axios
          .get("persons-belongs-to-category?category_id=" + data.id)
          .then((response) => { 
            if(response.status == 200)
            {
              this.peoplesCategory = response.data;
              this.nameCategory = data.description;
              this.openModalCategory = true;
            }
          })
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-category')));
      },
      balanceUser() {
        this.loadBalance = true;
        this.balance = 0;
        this.$axios
          .get('balance')
          .then((response) => { 
            if(response.status == 200)
            {
              this.balance += parseFloat(response.data.data.balance);
            }
          })
          .catch(e => {})
        this.$axios
          .get('get-balance-from-twilio')
          .then((response) => { 
            this.balance += parseFloat(response.data.balance);
            if(this.balance != 0)
            {
              if(!this.balance)
              {
                this.hasErrorOnRegister = true;
              }
            }
          })
          .catch(e => {this.hasErrorOnRegister = true})
          .finally(() => this.loadBalance = false);
      },
      submitSms() {
        this.sendSmsTemplate = false;
        this.loadingSmsTemplate = true;
        this.$axios
          .post("send-message-dashboard", {
            message: this.message,
            categories: this.categorySelectedId.length > 0 ? this.categorySelectedId : '',
            persons: this.peopleSelectedId,
            action: 'send'
          })
          .then((response) => { 
            if(response.data.status == "success")
            {
              this.loadingSmsTemplate = false;
              this.openModalProgress = true;
              this.qtdRecipients = response.data.qtt_recipients;
              this.toast('success',this.$t('be-t-success-submit-sms'));
            }
          })
          .catch(e => {
            let errorMessage = e.response.data.message;
            if(errorMessage.indexOf('A2P10DLC') !== -1)
            {
              errorMessage = this.$t('be-a2p-error-when-send') + `<br><a href='https://www.twilio.com/docs/errors/20404' class='ba-text-uppercase' target='_blank'>${this.$t('ge-more-info')}</a>`;
            }
            this.errorMessage = errorMessage;
            this.openModalError = true;
          })
          .finally(f => {
            this.loadingSmsTemplate = false;
          });
      },
      reset() {
        this.errorMessage = "";
        this.openModalError = false;
        this.sendSmsTemplate = true;
        this.showTablePerson = true;
        this.showTableCategory = true;
        this.currentPageCategory = 1;
        this.currentPagePerson = 1;
        this.perPageCategory = 7;
        this.perPagePerson = 7;
        this.searchCategory = "";
        this.searchPerson = "";
        this.current_step = 1;
        this.max_step = 4;
        this.openModalSubmit = false;
        this.openModalCategory = false;
        this.openModalProgress = false;
        this.selectRecipient = true;
        this.message = '';
        this.qtdRecipients = 0;
        this.typeRecipient = null;
        this.nameCategory = null;
        this.valueTotal = null;
        this.peoplesCategory = [];
        this.categorySelected = [];
        this.categorySelectedId = [];
        this.peopleSelected = [];
        this.peopleSelectedId = [];
        this.hasA2P = false;
        this.showA2PAlert = false;
      }
    },
    computed: {
      rowsCategory() {
        return this.categories.length
      },
      rowsPerson() {
        return this.peoples.length
      },
      progress() {
        return Math.round(100 / this.max_step) * this.current_step;
      },
      stateMessage() {
        if(this.message.length >= 5 && this.message.length < 161)
        {
          return true;
        }
        else
        {
          return false;
        }
      }
    },
    watch: {
      searchCategory(newValue, oldValue) {
        if(newValue == '')
        {
          this.searchRegister('',0);
        }
      },
      searchPerson(newValue, oldValue) {
        if(newValue == '')
        {
          this.searchRegister('',1);
        }
      }
    },
    mounted() {
      var lineProgress = document.querySelector('.progress-line');
      lineProgress.style.width = `${this.progress}%`;
      this.getData();
      this.$axios
        .get("category")
        .then((response) => {this.categories = response.data.data})
        .catch(e => this.toast('danger',this.$t('be-t-error-loading-category')));
      this.$axios
          .get("person")
          .then((response) => {this.peoples = response.data.data})
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-peoples'))); 
    }
  }
</script>

<style>
  .box-recipients{
    align-items: center; 
    margin-bottom: 1em;
  }
  .box-recipients p{
    margin-bottom: 0;
  }
  .progress-line{
    width: 0;
    transition: width 0.8s linear;
  }
  .card-style {
    width: 90%;
    margin: 0 auto;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background: dodgerblue; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(1, 127, 200); 
  }
  #tableCategory,
  #tablePeople{
    overflow: auto;
  }
</style>