import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages } from '../../static/translation/index'

Vue.use(VueI18n)

const locale = 'en_US'

export const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en_US',
  silentTranslationWarn: false,
  messages
})
