<template>
  <bui-page>
    <template #page-header-left>
      <h1 class="bui-page-title" v-html="$t('be-watch-our-tutorials')"></h1>
      <p v-html="$t('be-step-by-step-send-sms')"></p>
    </template>
    <template>
      <div id="tutorials">
        <b-tabs card>
          <b-tab class="text-center" :title="$t('be-how-to-create-twilio-account')" active v-html="createAccount()"></b-tab>
        </b-tabs>
        <b-tabs card>
          <b-tab class="text-center" :title="$t('be-how-to-buy-twilio-credits')" active v-html="buyCredits()"></b-tab>
        </b-tabs>
      </div>
    </template>
  </bui-page>
</template>
<script>
  const lang = localStorage.getItem('smslocale');
  export default {
    data() {
      return {  
      }
    },
    methods: {
      createAccount() {
        console.log(lang);
        if(lang == 'pt_BR')
        {
          return '<iframe width="560" height="315" src="https://www.youtube.com/embed/ADpnxg-BQ-o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        } 
        else if(lang == 'es_ES')
        {
            return '<iframe width="560" height="315" src="https://www.youtube.com/embed/ZvScaYYHRgU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        }
        else
        {
          return '<iframe width="560" height="315" src="https://www.youtube.com/embed/TU4ttVOy3xs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        }
      },
      buyCredits() {
        if(lang == 'pt_BR')
        {
            return '<iframe width="560" height="315" src="https://www.youtube.com/embed/dBjU0LoJXSo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        } 
        else if(lang == 'es_ES')
        {
            return '<iframe width="560" height="315" src="https://www.youtube.com/embed/lI5TRn0fHzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        }
        else
        {
          return '<iframe width="560" height="315" src="https://www.youtube.com/embed/6xMNTVgiH8Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        }
      },
      toast(variant,text) {
        this.$bvToast.toast(text, {
          variant: variant
        })
      },
    },
    mounted() {
    }
  }
</script>

<style>
  #card{
    width: 200px;
    box-shadow: 5px 10px 8px #888888;
  }
  .img-home-no{
    max-width: 30rem;
  }
  .bui-card-item{
    max-width: 15rem;
  }
  @media only screen and (max-width: 950px) {
    .img-home-no{
      max-width: 18rem;
    }
  }
  @media only screen and (max-width: 880px) {
    .bui-card-container{
      height: 50px;
    }
    .img-home-no{
      max-width: 15rem;
    }
  }
  @media only screen and (max-width: 760px) {
    #cards{
      text-align: center;
      margin-top: 10%;
      margin-left: 20%;
    }
  }
</style>