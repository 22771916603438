import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const sidebarStateKey = 'bui_store_sidebar_state'

export default new Vuex.Store({
  state: {
    sidebarState: localStorage.getItem(sidebarStateKey) || 'expanded',
    user: {
      id: null,
      name: null,
      email: null,
      gravatar: null,
    }
  },
  mutations: {
    toggleSidebar(state, payload) {
      state.sidebarState = payload
      localStorage.setItem(sidebarStateKey, payload)
    },
    storeUser(state, payload) {
      state.user = {
        id: payload.id,
        name: payload.name,
        email: payload.email,
        locale: payload.language,
        gravatar: payload.gravatar,
        is_admin: payload.is_admin
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
