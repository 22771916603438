<template>
  <div>
    <div class="ba-card ba-w-100 ba-flex ba-f-column">
      <div class="ba-top">
        <span class="ba-title ba-montserrat-700 span-size">{{  $t('ge-settings') }} </span>
      </div>
      <builderall-loader :loading="showForm == false" class="ba-content ba-flex ba-gp-1 ba-f-column ba-w-100">
        <div class="ba-box-tabs">
          <a
            v-for="(t, i) in tabs"
            :key="i"
            :class="[
              'ba-tab',
              t.status ? 'ba-is-active' : '',
              i == 3 ? 'ba6-red-element' : '',
            ]"
            @click="defineTabStatus(i)"
            v-text="t.title"
          ></a>
        </div>
        <br>
        <!-- Twilio Page -->
        <div v-if="tabs[0].status" class="ba-flex ba-f-column ba-gp-1">
          <div class="ba-content">
            <div class="ba-alert ba-error">
              <span>
                {{ $t('be-twilio-warning-1') }}
                <a href="https://www.twilio.com/login" target="_blank" class="ba-text-uppercase ba-montserrat-bold link-alert" v-text="$t('ge-click-here')"></a>
                {{ $t('be-twilio-warning-2') }}
              </span>
            </div>
          </div>
          <div class="ba-box-input ba-md ba-flex ba-f-column ba-w-100">
            <div class="ba-info">
              <div class="ba-info-left">
                <label class="ba-label" for="name">
                  {{ $t('be-twilio-account-sid') }}
                  <span class="ba-color-red1">*</span>
                </label>
              </div>
            </div>
            <input
              type="text"
              :class="['ba-input ba-w-100', sid && sid.length == 34 ? '' : 'ba-error']"
              v-model="sid"
              :placeholder=" $t('be-add-sid')"
            />
          </div>
          <div class="ba-box-input ba-md ba-flex ba-f-column ba-w-100">
            <div class="ba-info">
              <div class="ba-info-left">
                <label class="ba-label" for="name">
                  {{ $t('be-twilio-token') }}
                  <span class="ba-color-red1">*</span>
                </label>
              </div>
            </div>
            <input
              type="text"
              :class="['ba-input ba-w-100', token && token.length == 32 ? '' : 'ba-error']"
              v-model="token"
              :placeholder="$t('be-add-twlio-token')"
            />
          </div>
          <div class="ba-box-input ba-md ba-flex ba-f-column ba-w-100">
            <div class="ba-info">
              <div class="ba-info-left">
                <label class="ba-label" for="name">
                  {{$t('be-phone-number') }}
                  <span class="ba-color-red1">*</span>
                </label>
              </div>
            </div>
            <input
              type="tel"
              :class="['ba-input ba-w-100', number && number.length == 10 || number.length == 11 ? '' : 'ba-error']"
              v-model="number"
              :placeholder=" $t('be-add-phone') "
            />
          </div>
          <div class="ba-container ba-grid ba-gp-04">
            <div
              class="ba6-switch-element ba-flex ba-gp-06 ba-ai-center"
              ba-tooltip-position="left"
            >
              <label class="ba-switch ba-md">
                <input type="checkbox" v-model="statusActive" class="ba-checkbox" />
                <span class="ba-slider"></span>
              </label>
              <label
                for="sponsored-box-switch"
                class="ba-ba-monstserrat-700 ba-color-dark5 ba6-switch-span"
                >{{ $t('be-pause-sms') }}</label
              >
            </div>
          </div>
          <div class="ba-flex ba-jc-fe">
            <button 
              :class="['ba-btn ba-md ba-blue', !stateSid || !stateToken || !stateNumber ? 'ba-disabled' : '']" 
              :disabled="!stateSid || !stateToken || !stateNumber" 
              @click="save()"
            >{{ $t('be-save') }}</button>
          </div>
        </div>
        <!-- A2P Page -->
        <div loading='true' v-else-if="tabs[1].status" class="ba-flex ba-f-column ba-gp-1">
          <div class="ba-content">
            <div class="ba-alert ba-error">
              <span>
                {{ $t('be-twilio-a2p-warning-1') }}
                <a href="https://support.twilio.com/hc/en-us/articles/1260801864489-How-do-I-register-to-use-A2P-10DLC-messaging" target="_blank" class="ba-text-uppercase ba-montserrat-bold link-alert" v-text="$t('ge-click-here')"></a>
                {{ $t('be-twilio-a2p-warning-2') }}
              </span>
            </div>
          </div>
          <div class="ba-box-input ba-md ba-flex ba-f-column ba-w-100">
            <div class="ba-info">
              <div class="ba-info-left">
                <label class="ba-label" for="name">
                  {{ $t('be-messagin-sid') }}
                  <span class="ba-color-red1">*</span>
                </label>
              </div>
            </div>
            <input
              type="text"
              :class="['ba-input ba-w-100', a2p && a2p.length >= 30 ? '' : 'ba-error']"
              v-model="a2p"
              :placeholder="$t('be-add-messagin-sid')"
            />
          </div>
          <div class="ba-flex ba-jc-fe">
            <button 
              :class="['ba-btn ba-md ba-blue', a2p && a2p.length >= 30 ? '' : 'ba-disabled']" 
              :disabled="!a2p && a2p < 30" 
              @click="validateA2P()"
            >{{ $t('be-save') }}</button>
          </div>
        </div>
      </builderall-loader>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: [
        { title: 'Twilio', status: true },
        { title: 'A2P',    status: false },
      ],
      sid: "",
      token: "",
      number: "",
      a2p: "",
      twilioUserId: "",
      userId: this.$store.state.user.id,
      active: false,
      statusActive: false,
      showForm: true,
      exists: false,
      stateSid: false,
      stateToken: false,
      stateNumber: false,
      hasA2P: false,
      showEnom: false
    };
  },
  mounted(){
    const urlParams = window.location.search;
    const params = new URLSearchParams(urlParams);

    if (params.has('page') && params.get('page') == 'a2p') {
      this.defineTabStatus(1);
      this.getA2PStatus();
    }
  },
  methods: {
    getA2PStatus(){
      const email = localStorage.getItem('smsuseremail')
      this.$axios
        .post('verify-a2p10dlc', {
            email: email,
            origin: 'dashboard'
        })
        .then((response) => {
            this.hasA2P = false;
        })
        .catch(e => {
            this.hasA2P = true;
            this.toast("danger", this.$t('be-invalid-a2p'));
        }) 
    },
    defineTabStatus(index) {
      this.tabs.find((x) => x.status).status = false;
      this.tabs[index].status = true;
      if(index == 1 && this.a2p)
      {
        this.getA2PStatus();
      }
    },
    save() {
      if (!this.stateSid || !this.stateToken || !this.stateNumber) {
        this.toast("warning", this.$t("be-t-warning-complete-fields"));
      } else {
        this.createUpdateRegister();
      }
    },
    validateA2P(){
      if (!this.a2p) {
        this.toast("warning", this.$t("be-t-warning-complete-fields"));
      } else {
        this.saveA2P();
      }
    },
    saveA2P(){
      this.showForm = false;

      this.$axios
        .put(`twilio-user-msg-id/${this.twilioUserId}?message_id=${this.a2p}`)
        .then((response) => {
          if (response.status == 200) {
            this.toast("success", this.$t("be-success-create-register"));
            this.getData();
          } else {
            this.toast("danger", this.$t("be-error-create-register"));
          }
        })
        .catch((e) =>
          this.toast("danger", this.$t("be-error-create-register"))
        )
        .finally(() => {
          this.showForm = true;
        });
    },
    createUpdateRegister() {
      this.showForm = false;
      let data = {
        twilio_user_id: this.twilioUserId,
        sid: this.sid,
        token: this.token,
        phone_number: this.number,
        active: this.statusActive ? 0 : 1,
        message_id: this.a2p
      };
      if (this.exists) {
        // update
        let parameters = `${data["twilio_user_id"]}?sid=${data["sid"]}&token=${data["token"]}&phone_number=${data["phone_number"]}&active=${data["active"]}&message_id=${data["message_id"]}`;
        this.$axios
          .put("twilio_user/" + parameters)
          .then((response) => {
            if (response.status == 200) {
              this.toast("success", this.$t("be-success-update-register"));
              this.getData();
            } else {
              this.toast("danger", this.$t("be-error-update-register"));
            }
          })
          .catch((e) =>
            this.toast("danger", this.$t("be-error-update-register"))
          )
          .finally(() => {
            this.showForm = true;
          });
      } else {
        // create
        let parameters = `sid=${data["sid"]}&token=${data["token"]}&phone_number=${data["phone_number"]}&active=${data["active"]}`;
        this.$axios
          .post("twilio_user?" + parameters)
          .then((response) => {
            if (response.status == 201) {
              this.toast("success", this.$t("be-success-create-register"));
              this.getData();
            } else {
              this.toast("danger", this.$t("be-error-create-register"));
            }
          })
          .catch((e) =>
            this.toast("danger", this.$t("be-error-create-register"))
          )
          .finally(() => {
            this.showForm = true;
          });
      }
    },
    getData() {
      this.showForm = false;
      this.$axios
        .get("twilio_user-by-user")
        .then((response) => {
          if (response.status == 200) {
            this.sid = response.data.sid;
            this.token = response.data.token;
            this.number = response.data.phone_number;
            this.active = response.data.active;
            this.twilioUserId = response.data.id;
            this.exists = true;
            this.a2p = response.data.message_id;
          } else {
            this.exists = false;
            this.toast("danger", this.$t("be-error-get-data"));
          }
        })
        .catch((e) => {
          if (e.response.data.message == "Record not found!") {
            this.exists = false;
            this.toast("danger", this.$t("be-insert-data-to-submit-sms"));
          } else {
            this.toast("danger", this.$t("be-error-get-data"));
          }
        })
        .finally(() => {
          this.showForm = true;
        });
    },
    toast(variant, text) {
      this.$bvToast.toast(text, {
        variant: variant,
      });
    },
  },
  created() {
    this.getData();
  },
  watch: {
    active(newVal){
      this.statusActive = newVal || newVal == 1 ? false : true;
    },  
    sid() {
      if (this.sid.length == 34) {
        this.stateSid = true;
      } else {
        this.stateSid = false;
      }
    },
    token() {
      if (this.token.length == 32) {
        this.stateToken = true;
      } else {
        this.stateToken = false;
      }
    },
    number() {
      if (this.number.length > 9 && this.number.length < 12) {
        this.stateNumber = true;
      } else {
        this.stateNumber = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.span-size {
  font-size: 24px;
}

.subtitle {
  font-weight: 200;
}

.btn_action {
  float: right !important;
}

.alert-message {
  background-color: #ee7f94;
  color: #fff;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  font-weight: 200;
}

label{
  margin-bottom: 0;
}

.link-alert{
  padding: 0 !important;
  margin: 0 !important;
}
.ba-alert{
  a{
    color: inherit;
    &:hover{
      color: black;
    }
  }
}
</style>
