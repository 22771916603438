<template>
<div>
    <bui-navbar 
        :key="$store.state.user.id"
        :sidebar-state="$store.state.sidebarState" 
        :logo="logo" 
        @toggle-sidebar="toggleSidebar"
        :user="$store.state.user"
        :label-logout="$t('ge-logout')"
        :label-favorite-lang="$t('ge-language')"
        @change-language="languageTurn"
        @logout="logoutUser()"
    >
        <template #dropdown-item-after>
            <b-dropdown-item tabindex="-1" link-class="py-2">
                <b-link to="/data-user" class="b alter_data">
                    <bui-icon name="gear" class="mx-3"/>
                    {{ $t('ge-change-data') }}
                </b-link>
            </b-dropdown-item>
        </template>
    </bui-navbar>
</div>
</template>

<script>
import Cookie from '@/service/cookie' 
export default {
    data() {
        return {
            logo: require('@/assets/images/logo.png') 
        }
    },
    methods: {
        toggleSidebar(state) {
            this.$store.commit('toggleSidebar', state);
        },
        toast(variant,text) {
            this.$bvToast.toast(text, {
            variant: variant
            })
        },
        languageTurn(newLocale) {
            this.$axios
                .post('language', {language: newLocale})
                .then((response) => { 
                    localStorage.setItem('smslocale', newLocale);
                })
                .catch(e => {console.log(e);this.toast('warning', this.$t('be-t-error-language-turn'))});
        },
        logoutUser() {
            Cookie.deleteToken();
			let urlOk = document.URL;
			let url = '';
			//console.log('Url: ' + urlOk);
			if(urlOk.indexOf('sms-front.kub.app.builderall.info') != -1)
			{
				// homolog
				url = "https://office.kub.app.builderall.info/us/office/sms";
			}
			else if(urlOk.indexOf('sms-front.kub.app.builderall.io') != -1)
			{
				// test
				url = "https://office.kub.app.builderall.io/us/office/sms";
			}
			else
			{
				// prod or local
				url = "https://office.kub.app.builderall.com/us/office/sms";
			}
            location.href = url;
        }
    }
  }
</script>

<style lang="scss" scoped>
    .alter_data{
        display: flex;
        align-items: center;
        text-decoration: none;
}
</style>>