<template>
  <div>
      <div class='ba-app-content'>
        <builderall-banner 
          :title="$t('be-sms-messaging')"
          :subtitle="$t('be-title-banner')"
          :description="$t('be-subtitle-banner')"
        />
        <div class='ba-app-wrapper ba-gp-1'>
            <div class='ba-sidebar'>
                <ul class='ba-nav'>
                    <li v-for="d,i in internSidebar" :key="i" class='ba-nav-item'>
                        <b-link :to='d.link' :class="{'ba-link': true, 'ba-is-active': routeIsActive(d.link)}">
                            <span class="ba-icon" v-html="d.icon"></span>
                            <span class='ba-title' v-text="d.title"></span>
                        </b-link>
                    </li>
                </ul>
                <div class='ba-bottom'>
                  <ul class='ba-bottom-list'>
                      <li>
                          <builderall-help />
                      </li>
                  </ul>
                </div>
            </div>
            <div class='ba-container ba-flex ba-gp-1 ba-f-column'>
              <base-breadcrumb v-if="!routeIsActive('/')" :currentPage="currentPage" />
              <bui-container :sidebar-state="$store.state.sidebarState">
                <router-view></router-view>
              </bui-container>
            </div>
            <div class='ba-flex ba-jc-center ba-pd-b-1 ba-ai-end'>
              <builderall-footer></builderall-footer>
            </div>
        </div>
      </div>
  </div>
</template>

<script>

import TheNavbar from '../layout/TheNavbar'
import TheSidebar from '../layout/TheSidebar'
import Guard from '@/service/middleware'
import BaseBreadcrumb from '../BaseBreadcrumb.vue'

export default {
  components: {
    TheNavbar,
    TheSidebar,
    BaseBreadcrumb
  },
  beforeRouteEnter: Guard.redirectIfNotAuthenticated,
  data() {
    return {
      internSidebar: [
        {
          icon: "<builderall-icon code='sms' />",
          title: this.$t('ge-dashboard'),
          link: '/'
        },
        {
          icon: "<builderall-icon code='6a20' />",
          title: this.$t('be-submit-sms'),
          link: '/submit-sms'
        },
        {
          icon: "<builderall-icon code='b80f' />",
          title: this.$t('be-sent-messages'),
          link: '/historic'
        },
        {
          icon: "<builderall-icon code='6d01' />",
          title: this.$t('be-peoples'),
          link: '/peoples'
        },
        {
          icon: "<builderall-icon code='56ba' />",
          title: this.$t('be-groups'),
          link: '/categories'
        },
        {
          icon: "<builderall-icon code='7a17' />",
          title: this.$t('be-import-list'),
          link: '/import-list'
        },
        {
          icon: "<builderall-icon code='e2d0' />",
          title: this.$t('be-pricing-table'),
          link: '/prices'
        },
        {
          icon: "<builderall-icon code='settings' />",
          title: this.$t('ge-settings'),
          link: '/data-user'
        },
      ],
    currentPage: '',
    }
  },
  methods: {
    routeIsActive(route){
        if(this.$route.path === route)
        {
            this.currentPage = route;
            return true;
        }
    }
  }
}
</script>

<style lang="scss">
    .ba-app-content{
        .ba-banner{
            background-image: url(../../assets/images/banner.jpg);
            background-size: cover;
            .ba-header{
                .ba-header-left{
                    .ba-icon{
                        background-color: #0060E3;
                        svg{
                            fill: white;
                            margin-top: .3rem;
                        }
                    }
                }
            }
            .ba-app-wrapper{
              .ba-footer{
                .ba-nav{
                  a:not(:first-child){
                    &:hover{
                      text-decoration: underline;
                    }
                  }
                  hr{
                    width: 1px;
                    height: 14px;
                    background-color: #92aac6;
                  }
                }
              }
            }
        }
    }
</style>