import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './plugins/i18n'
import './plugins/axios'

import { BootstrapVue } from 'bootstrap-vue';
import { BuilderallVueUi } from 'builderall-vue-ui';
import '@/sass/app.scss'; // (the same file as in step 2)
import 'builderall-vue-ui/dist/builderall-vue-ui.css';
import 'builderall-vue-ui/src/assets/fonts/fonts.css';

const BV_CONFIG = {
  BToast: {
    toaster: 'b-toaster-bottom-right',
    appendToast: true,
    noCloseButton: true,
    variant: 'success'
  }
}

Vue.use(BootstrapVue, BV_CONFIG);

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(BuilderallVueUi, {
  rtl: document.documentElement.dir === 'rtl'
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
