<template>
  <bui-page>
    <h1 v-if="importList" class="bui-page-title">
      {{ $t('be-import-title') }}
    </h1>
    <div v-if="modalConfirm">
      <!-- Modal confirm list csv -->
      <b-modal v-model="modalConfirm" :title="$t('be-file') + ': ' + this.fileList[0]['name']" centered no-fade ok-variant="success" :ok-title="$t('ge-submit')" :cancel-title="$t('ge-cancel')" @ok="sendFile()">
        <bui-form-group :label="$t('be-type-separator') + ':'" :help="$t('be-help-type-separator')" required>
          <b-form-select :options="typeSeparateFields" :state="!typeSeparate ? false : true" v-model="typeSeparate"/>
        </bui-form-group>
        <bui-form-group :label="$t('be-category-name') + ': '" :help="$t('be-file-in-category')">
          <b-form-input type="text" v-model="nameCategory" :placeholder="$t('be-placeholder-new-category-import')" v-b-popover.hover.top="$t('ge-optional')"/>
        </bui-form-group>
      </b-modal>
    </div>
    <div>
    </div>
    <div class="bg-white mt-5 text-center p-5">
      <h2 class="text-success">{{ $t('be-import-subtitle') }} <b class="text-primary">.CSV</b></h2>
      <hr>
      <div class="ba-flex ba-ai-center ba-jc-sb">
        <h4 class="text-primary text-left">{{ $t('be-follow-example-import') }}:</h4>
        <a href="/sms_example.csv" download>{{ $t('be-download-example') }}</a>
      </div>
      <div class="d-flex justify-content-center p-3">
          <!-- class="bg-success text-white"  -->
        <b-table stacked="md" class="bg-white text-center table-bordered" hover responsive :items="items" :fields="fields">
          <template #head(name)="name">
            <span class="text-primary" v-b-popover.hover.bottom="$t('be-column-optional')">{{ name.label }}</span>
          </template>
          <template #head(phone)="phone">
            <span v-b-popover.hover.bottom="'DDD + phone'">phone</span>
          </template>
          <template #head(cod_country)="cod_country">
            <span v-b-popover.hover.bottom="'DDI'">cod_country</span>
          </template>
        </b-table>
      </div>
      <hr>
      <div class="box-file" @dragover="dropFile()">
        <builderall-loader :loading="showSpinner" v-if="boxFileSelect">
          <label for="fileCsv" class="btn btn-outline-success">{{ $t('be-choose-file') }}
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-box-arrow-up text-primary ml-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z"/>
              <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708l3-3z"/>
            </svg>
          </label>
          <br>
          <p>{{ $t('be-drag-drop') }}</p>
          <p class="box-flex" v-if="fileList != null && boxFileSelect">
            {{ this.fileList[0]["name"] }}
            <bui-icon v-if="hasError" name="circle-incomplete" class="mx-1"/>
          </p>
        </builderall-loader>
      </div>
      <input v-show="boxFileDrag" id="fileCsv" class="form-control form-control-lg border-primary text-primary" accept=".csv" type="file" @change="previewFiles">
      <br>
      <button class="btn btn-primary" @click="submitFile()">{{ $t('ge-continue') }}</button>
    </div>
  </bui-page>
</template>

<script>
  export default {
    data() {
      return {
        importList: true,
        listsUser: false,
        boxFileSelect: true,
        boxFileDrag: false,
        showSpinner: false,
        hasError: false,
        fileList: null,
        nameCategory: null,
        showDigitalFileRepository: false,
        fields: [
          {key: 'cod_country', label: 'cod_country'},
          {key: 'phone', label: 'phone'},
          {key: 'name', label: 'name', variant: "success"},
        ],
        items: [
          {cod_country: 7, phone: 3194567891, name: "Jhon"},
          {cod_country: 55, phone: 5496543212, name: "Gustavo"},
          {cod_country: 1, phone: 1194567894, name: "Mary"},
          {cod_country: 971, phone: 4396543218, name: "Erick"},
        ],
        typeSeparateFields: [
          {value: ';', text: ';'},
          {value: ',', text: ','},
          {value: '|', text: '|'},
          {value: ':', text: ':'},
        ],
        modalConfirm: false,
        typeSeparate: null,
      }
    },
    methods: {
      backHome() {
        this.importList = true;
        this.listsUser = false;
      },
      submitFile() {
        // Max 200kb -> 200000bytes
        if(!this.fileList)
        {
          this.toast('danger',this.$t('be-t-first-choose-file'));
        }
        else
        {
          if(this.fileList[0]["name"].indexOf(".csv") == -1)
          {
            this.toast('warning',this.$t('be-t-invalid-format'));
          }
          else
          {
            if(this.fileList[0]["size"] < 200000)
            {
              this.modalConfirm = true;
            }
            else
            {
              this.toast('warning',this.$t('be-t-choose-smaller-file'));
            }
          }
        }
      },
      dropFile() {
        this.boxFileSelect = false;
        this.boxFileDrag = true;
        document.querySelector('.box-file').style.border = "none";
      },
      sendFile() {
        if(!this.typeSeparate)
        {
          this.toast('warning',this.$t('be-t-choose-type-separator'));
        }
        else
        {
          let formData = new FormData();
          let headers = {'Content-Type': 'multipart/form-data'};
          formData.append('file', this.fileList[0]);
          formData.append('separator', this.typeSeparate);
          formData.append('category_name', this.nameCategory);
          this.showSpinner = true;
          this.hasError = false;
          this.$axios
            .post("import-list",formData,headers)
            .then((response) => { 
              this.hasError = false;
              if(response.status == 200)
              {
                this.reset();
                this.toast('success',this.$t('be-t-success-import'));
              }
            })
            .catch(e => {
              this.hasError = true;
              if(e.response.data.error_message == 'invalid_values')
              {
                let qtdErrors = (e.response.data.invalid_lines).length;
                let errorMessage = qtdErrors > 1 ? 'erros' : 'erro';
                this.toast('danger',this.$t('ge-you-has') + ' '+ qtdErrors + ' ' + errorMessage + ' ' + this.$t('be-in-list'));
                let qtdToFor = qtdErrors >= 4 ? 4 : qtdErrors;
                for(let i = 0; i < qtdToFor; i++)
                {
                  this.toast('danger',this.$t('ge-line') + ' ' + e.response.data.invalid_lines[i]['Line'] + ': ' + e.response.data.invalid_lines[i]['details']);
                }
                if(qtdErrors > 4)
                {
                  this.toast('danger','...');
                }
              }
              else if(e.response.data.error_message == 'separator')
              {
                this.toast('danger',this.$t('be-t-error-type-separator'));
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-import'));
              }
            })
            .finally(f => {
              this.showSpinner = false;
            });
        }
      },
      previewFiles(event) {
        console.log(event)
        if(event.target.files.length > 0)
        {
          this.fileList = event.target.files;
          this.hasError = null;
        }
      },
      toast(variant,text) {
        this.$bvToast.toast(text, {
          variant: variant
        })
      },
      reset() {
        this.importList = true;
        this.listsUser = false;
        this.nameCategory = null;
        this.modalConfirm = false;
        this.typeSeparate = null;
        this.fileList = null;
        this.hasError = null;
      }
    },
  }
</script>

<style>
  input[type='file'] {
    border: 1px dashed dodgerblue;
    padding: 50px;
    background-color: rgb(243, 251, 255);
  }
  .box-file{
    border: 1px dashed dodgerblue;
    padding: 15px;
  }
  .box-flex{
    display: flex;
    justify-content: center;
    gap: .3rem;
    align-items: center;
  }
</style>