<template>
  <bui-page>
    <template #page-header-left>
      <h1 class="bui-page-title">
        {{ $t('be-sent-messages-report') }}
      </h1>
    </template>
    <template #page-header-right>
        <b-button :title="$t('ge-search')" variant="success" @click="searchSubmit()" class="mt-4">
          {{ $t('ge-search') }}
        </b-button>
    </template>
    <!-- Modal info row -->
    <div v-if="modalShowRow">
      <b-modal v-model="modalShowRow" :title="$t('be-details-submit')" centered no-fade hide-footer>
        <b-container class="bv-example-row">
          <b-row>
            <b-col>
              <label>{{ $t('be-date-create') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlDateCreate }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>{{ $t('be-modal-country-person') }}</label>
              <p class="h5 text-center border border-success bg-white p-2">{{ mlCountry }}</p>
            </b-col>
            <b-col>
              <label>{{ $t('ge-price') }}</label>
              <p class="h5 text-center border border-success bg-white p-2">USD $ {{ parseFloat(mlPrice).toFixed(3) }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>{{ $t('be-message') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlMsg }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>{{ $t('be-origin-relatory') }}</label>
              <p class="h5 text-center border border-success bg-white p-2">{{ mlOrigin }}</p>
            </b-col>
            <b-col>
              <label>{{ $t('be-status-relatory') }}</label>
              <p class="h5 text-center border border-success bg-white p-2">{{ mlStatus }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>{{ $t('be-name-recipient') }}</label>
              <p class="h5 text-center border border-success bg-white p-2">{{ mlNameRecipient }}</p>
            </b-col>
            <b-col>
              <label>{{ $t('be-for-phone') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlPhone }}</p>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
    </div>
    <!-- Modal info twilio for admin -->
    <div v-if="modalShowAdm">
      <b-modal v-model="modalShowAdm" :title="$t('be-title-modal-adm')" centered no-fade hide-footer>
        <b-container class="bv-example-row">
          <b-row>
            <b-col>
              <label>{{ $t('be-date-create') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlDateCreate }}</p>
            </b-col>
            <b-col>
              <label>{{ $t('be-date-update') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlDateUpdate }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>{{ $t('be-num-segments') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlSegments }}</p>
            </b-col>
            <b-col>
              <label>{{ $t('be-date-submit') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlDateSubmit }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>{{ $t('be-message') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlMsg }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>{{ $t('be-status-relatory') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlStatus }}</p>
            </b-col>
            <b-col>
              <label>{{ $t('ge-price') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">USD $ {{ parseFloat(mlPrice).toFixed(3) }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>User id</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlUserId }}</p>
            </b-col>
            <b-col>
              <label>Person id</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlPersonId }}</p>
            </b-col>
          </b-row>
          <b-row v-if="mlMsgErro != null">
            <b-col>
              <label>{{ $t('be-message-error') }}</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlMsgErro }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>Sid</label>
              <p class=" h5 text-center border border-success bg-white p-2">{{ mlSid }}</p>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
    </div>
    <div>
      <b-container class="bv-example-row">
        <b-row v-if="isAdmin">
          <b-col>
            <bui-form-group :label="$t('be-only-adm-relatory')">
              <section>
                <b-form-input style="height: 36px;" type="text" v-model="idEmailFilterAdm" placeholder="ID | EMAIL"/>
              </section>
            </bui-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <bui-form-group :label="$t('be-date-relatory')" required>
              <section>
                <date-picker v-model="periodSelected" type="datetime" @clear="cleanDate()" range></date-picker>
              </section>
            </bui-form-group>
          </b-col>
          <b-col>
            <bui-form-group :label="$t('be-origin-relatory')">
              <b-form-select class="h-25" :options="origin" v-model="originSelected"/>
            </bui-form-group>
          </b-col>
          <b-col>
            <bui-form-group :label="$t('be-phone-relatory')" :help="$t('be-phone-help-relatory')">
              <b-form-input style="height: 36px;" type="number" v-model="phoneFilter"/>
            </bui-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <bui-form-group :label="$t('be-status-relatory')">
              <b-form-select class="h-25" :options="shippingStatus" v-model="statusShippingSelected"/>
            </bui-form-group>
          </b-col>
          <b-col>
            <bui-form-group :label="$t('be-country-relatory')">
              <b-form-select :options="country" class="mb-3 h-25" value-field="id" v-model="countrySelected" text-field="description"></b-form-select>
            </bui-form-group>
          </b-col>
        </b-row>
      </b-container>
      <builderall-loader :loading='showTable == false'> 
      <div class="mt-2">
        <b-table class="bg-white text-center" stacked="md" show-empty :empty-text="$t('ge-no-records-to-show')" hover :items="items" :fields="fields" :per-page="perPage" :current-page="currentPage">
          <template #cell(actions)="row">
            <bui-form-group>
              <button class="btn p-0">
                <svg xmlns="http://www.w3.org/2000/svg" @click="showRow(row)" width="20" height="20" fill="currentColor" class="bi bi-eye text-primary showRow" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </button>
              <button v-if="isAdmin" class="btn p-0 ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" @click="showForAdm(row)" width="20" height="20" fill="currentColor" class="bi bi-card-list text-success showRow" viewBox="0 0 16 16" v-b-popover.hover.rigth="$t('be-title-modal-adm')">
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                  <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                </svg>
              </button>
            </bui-form-group>
          </template>
        </b-table>
        <b-container class="bv-example-row">
        <b-row>
          <b-col v-if="items.length > 0">
            {{ this.$t('ge-quantity') + ': ' + qtd_result}}
          </b-col>
          <b-col class="text-right">
            <b-pagination v-if="rows > 6" v-model="currentPage" :total-rows="rows" pills :per-page="perPage" aria-controls="my-table" align="right"></b-pagination>
          </b-col>
        </b-row>
      </b-container>
      </div>
      </builderall-loader>
    </div>
  </bui-page>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  export default {
    components: { DatePicker },
    data() {
      return {
        isAdmin: false,
        showTable: true,
        token: "ac7b0ada370283c8f28d7a42d670a993",
        mlDateCreate: null,
        mlDateUpdate: null,
        mlDateSubmit: null,
        mlSegments: null,
        mlPhone: null,
        mlMsg: null,
        mlPersonId: null,
        mlUserId: null,
        mlStatus: null,
        mlPrice: null,
        mlCountry: null,
        mlMsgErro: null,
        mlSid: null,
        mlOrigin: null,
        mlNameRecipient: null,
        idEmailFilterAdm: null,
        countrySelected: null,
        statusShippingSelected: '',
        phoneFilter: null,
        originSelected: 'not callback',
        qtd_result: null,
        modalShowAdm: false,
        modalShowRow: false,
        periodSelected: [],
        perPage: 6,
        currentPage: 1,
        origin: [
          {value: '', text: this.$t('be-all')}, 
          {value: 'not callback', text: this.$t('be-all-except-callbacks'), forAdm: true}, 
          {value: 'chatbot', text: 'ChatBot'}, 
          {value: 'mailing-boss', text: 'Mailing Boss'},
          {value: 'dashboard', text: 'Dashboard'},
          {value: 'booking', text: 'Booking'},
          {value: 'message-center', text: this.$t('be-message-center')},
          {value: 'callback', text: 'Callback', forAdm: true}
        ],
        categories: [],
        shippingStatus: [
          {value: '', text: this.$t('be-all')}, 
          {value: 'sent', text: this.$t('be-sent')}, 
          {value: 'delivered', text: this.$t('be-delivered')}, 
          {value: 'undelivered', text: this.$t('be-not-delivered')},
          {value: 'error', text: this.$t('ge-error')}
        ],
        country: [],
        fields: [
          {key: 'phone_number', label: this.$t('be-modal-phone-person'), sortable: true},
          {key: 'unit_price', label: this.$t('ge-value'), sortable: true},
          {key: 'date_create', label: this.$t('ge-date'), sortable: true},
          {key: 'status', label: this.$t('be-status-relatory')},
          {key: 'code_iso', label: this.$t('be-modal-country-person'), sortable: true},
          {key: 'origin', label: this.$t('be-origin-relatory'), sortable: true},
          {key: 'actions', label: ''},
        ],
        items: []
      }
    },
    methods: {
      toast(variant,text) {
        this.$bvToast.toast(text, {
          variant: variant
        })
      },
      cleanDate() {
        this.periodSelected = [];
      },
      searchSubmit() {
        if(this.periodSelected.length < 1)
        {
          this.items = [];
          this.qtd_result = null;
          this.toast('warning',this.$t('be-t-select-period'));
        }
        else
        {
          this.showTable = false;
          this.$axios 
            .get('report-send?date_start=' + this.periodSelected[0].toISOString() + 
            '&date_end=' + this.periodSelected[1].toISOString() +
            (!this.phoneFilter == false ? ('&phone_number=' + this.phoneFilter) : '') +
            (!this.statusShippingSelected == false ? ('&status=' + this.statusShippingSelected) : '') + 
            (!this.originSelected == false ? ('&origin=' + this.originSelected) : '') +
            (!this.countrySelected == false ? ('&country_price_id=' + this.countrySelected) : '') +
            (!this.idEmailFilterAdm == false ? ('&user_email=' + this.idEmailFilterAdm) : '')
            )
            .then((response) => {
              if(response.data.status == 'success')
              {
                this.items = response.data.messages;
                this.qtd_result = response.data.messages.length;
              }
              this.showTable = true;
            })
            .catch(e => {
              console.log(e);
              this.items = [];
              this.qtd_result = null;
              this.showTable = true;
            });
        }
      },
      showForAdm(data) {
        this.$axios 
            .get('get-by-sid?sid_message=' + data.item.sid + '&token=' + this.token)
            .then((response) => {
              if(response.status == 200)
              {
                this.mlDateCreate = response.data.message.date_created;
                this.mlDateUpdate = response.data.message.date_updated;
                this.mlDateSubmit = response.data.message.date_sent;
                this.mlSegments = response.data.message.num_segments;
                this.mlPrice = response.data.message.total_price;
                this.mlStatus = response.data.message.status;
                this.mlPersonId = data.item.person_id;
                this.mlUserId = data.item.user_id;
                this.mlSid = response.data.message.sid;
                this.mlMsg = response.data.message.message;
                this.mlMsgErro = response.data.message.error_message;
                this.modalShowAdm = true;
              }
            })
            .catch(e =>this.toast('danger',this.$t('be-t-error-search-item')));
      },
      showRow(data) {
        this.mlDateCreate = data.item.date_create;
        this.mlPhone = data.item.phone_number;
        this.mlMsg = data.item.message;
        this.mlOrigin = data.item.origin;
        this.mlStatus = data.item.status;
        this.mlPrice = data.item.unit_price;
        this.mlCountry = data.item.description;
        this.mlNameRecipient = data.item.name;
        this.modalShowRow = true;
      },
    },
    computed: {
      rows() {
        return this.items.length
      },
    },
    mounted() {
      if(this.$store.state.user.is_admin == 1)
      {
        this.isAdmin = true;
      }

      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 6);
      this.periodSelected = [thirtyDaysAgo,today]
      this.searchSubmit();

      this.$axios
          .get("country_price")
          .then((response) => {this.country = response.data.data})
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-country')));
    }
  }
</script>
<style>
  .showRow:hover{
      box-shadow: 0px 0px 30px black;
      background-color: rgba(0, 0, 0, 0.2);
  }
</style>