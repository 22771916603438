<template>
  <bui-page>
    <div v-if="this.isAdmin">
      <div class="row">
        <div class="col-sm-4">
          <h1 class="bui-page-title">
            Admin <b v-if="this.openCountry">({{ this.countryPrice.length }})</b><b v-if="this.openCountryAreaCode">({{ this.countryAreaCode.length }})</b>
          </h1>
        </div>
        <div class="col-sm-8 text-right">
          <div v-if="this.openCountryAreaCode">
            <b-container class="bv-example-row">
              <b-row>
                <b-col class="text-right">
                  <b-button :title="$t('be-new-register')" variant="primary" v-b-modal.modal-newEdit-country-area>
                    <bui-icon name="plus" variant="white" :size="18"/>
                    {{ $t('be-new-register') }}
                  </b-button>
                </b-col>
                <b-col class="text-left">
                  <b-button :title="$t('ge-back')" variant="light" @click="backHome()">
                    {{ $t('ge-back') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div v-if="this.openCountry">
            <b-container class="bv-example-row">
              <b-row>
                <b-col>
                  <b-input type="search" :placeholder="$t('ge-search')" v-model="search" @keyup.enter="searchCountry()" class="w-100 h-100"/>
                </b-col>
                <b-col class="text-center">
                  <b-button :title="$t('be-new-country')" variant="primary" v-b-modal.modal-newEdit-country>
                    <bui-icon name="plus" variant="white" :size="18"/>
                    {{ $t('be-new-country') }}
                  </b-button>
                </b-col>
                <b-col class="text-left">
                  <b-button :title="$t('ge-back')" variant="light" @click="backHome()">
                    {{ $t('ge-back') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <b-button v-if="this.statistics" title="Country prices" variant="success" class="mr-2" @click="openCountryPrice()">
            Country prices
          </b-button>
          <b-button v-if="this.statistics" title="Country area code" variant="success" @click="openCountryArea()">
            Country area code
          </b-button>
        </div>
      </div>
      <div>
        <!-- Modal destroy country -->
        <b-modal id="modal-delete-country" :title="$t('be-name') + ': ' + this.form.nameCountry" centered no-fade ok-variant="danger" :ok-title="$t('ge-delete')" :cancel-title="$t('ge-cancel')" @ok="confirmDeleteCountry()" @hide="reset()">
          <p class="my-4">{{ $t('be-certain-delete-country') }} ?</p>
        </b-modal>
        <!-- Modal destroy country area -->
        <b-modal id="modal-delete-country-area" :title="$t('be-delete-area-code') + ': ' + this.formCountryArea.areaCode" centered no-fade ok-variant="danger" :ok-title="$t('ge-delete')" :cancel-title="$t('ge-cancel')" @ok="confirmDeleteCountryArea()" @hide="reset()">
          <p>{{ $t('be-question-delete-register') }}</p>
        </b-modal>
      </div>
      <div>
        <!-- Modal new country -->
        <b-modal id="modal-newEdit-country" :title="$t('be-register-country')" centered no-fade ok-variant="success" :ok-title="$t('ge-confirm')" :cancel-title="$t('ge-cancel')" @ok="newEditCountry()" @hide="reset()">
          <bui-form-group :label="$t('be-name-country') + ':'" required>
            <b-form-input :state="!form.nameCountry ? false : true" type="text" v-model="form.nameCountry" :placeholder="$t('be-p-name-country')"/>
          </bui-form-group>
          <bui-form-group :label="$t('be-price-prices') + ':'" required>
            <b-form-input :state="!form.priceCountry ? false : true" type="number" v-model="form.priceCountry" :placeholder="$t('be-p-price-country')"/>
          </bui-form-group>
          <bui-form-group :label="$t('be-initials-country') + ':'" required>
            <b-form-input :state="form.codeIsoCountry.length == 2 ? true : false" type="text" v-model="form.codeIsoCountry" :placeholder="$t('be-p-initials-country')"/>
          </bui-form-group>
          <bui-form-group :label="$t('be-dialing-code-country')+':'" required>
            <b-form-input :state="!form.codeNumberCountry ? false : true" type="number" v-model="form.codeNumberCountry" :placeholder="$t('be-p-dialing-code-country')"/>
          </bui-form-group>
        </b-modal>
        <!-- Modal new country area -->
        <b-modal id="modal-newEdit-country-area" :title="$t('be-register-area-code')" centered no-fade ok-variant="success" :ok-title="$t('ge-confirm')" :cancel-title="$t('ge-cancel')" @ok="newEditCountryArea()" @hide="reset()">
          <bui-form-group :label="$t('be-code-number') + ':'" required>
            <b-form-input :state="!formCountryArea.codeNumber ? false : true" type="number" v-model="formCountryArea.codeNumber" :placeholder="$t('be-p-dialing-code-country')"/>
          </bui-form-group>
          <bui-form-group :label="$t('be-code-area') + ':'" required>
            <b-form-input :state="!formCountryArea.areaCode ? false : true" type="number" v-model="formCountryArea.areaCode" :placeholder="$t('be-enter-area-code')"/>
          </bui-form-group>
          <bui-form-group :label="$t('be-modal-country-person')">
              <b-form-select :state="!formCountryArea.countryPriceId ? false : true" :options="country" class="mb-3 h-25" value-field="id" v-model="formCountryArea.countryPriceId" text-field="description"></b-form-select>
            </bui-form-group>
        </b-modal>
      </div>
      <br>
      <div v-if="this.statistics">
          <div class="text-center">
            <iframe src="https://bi.services.builderall.com/public/dashboard/ab8cf71d-3075-42ba-8852-773a69fbeecb" style="border-radius: 15px;" frameborder="0" width="1100" height="1500" allowtransparency></iframe>
            <iframe src="https://bi.services.builderall.com/public/question/a1405ad3-30a5-4c40-ae3d-4bc03a26432f" frameborder="0" width="1100" height="600" allowtransparency></iframe>
          </div>
      </div>
      <div v-if="this.openCountry">
          <template>
            <div v-if="countryPrice.length > 0">
              <b-table class="bg-white" stacked="md" hover :items="countryPrice" :fields="fieldsCountryPrice" :per-page="perPage" :current-page="currentPage">
                <template #cell(actions)="row">
                  <b-button variant="light" class="btn btn-sm" @click="openEditCountry(row.item, row.index)" v-b-modal.modal-newEdit-country>
                    <bui-icon name="pencil" class="m-0" size="18"/>
                  </b-button>
                  <b-button variant="light" class="btn btn-sm ml-1" @click="openDestroyCountry(row.item, row.index)" v-b-modal.modal-delete-country>
                    <bui-icon name="trash" class="m-0" size="18"/>
                  </b-button>
                </template>
              </b-table>
              <b-pagination v-if="countryPrice.length > 9" v-model="currentPage" :total-rows="rowsCountryPrice" pills :per-page="perPage" aria-controls="my-table" align="right"></b-pagination>
            </div>
          </template>
      </div>
      <div v-if="this.openCountryAreaCode">
          <template>
            <div v-if="countryAreaCode.length > 0">
              <b-table class="bg-white" stacked="md" hover :items="countryAreaCode" :fields="fieldsCountryArea" :per-page="perPage" :current-page="currentPage">
                <template #cell(country_price_id)="data">
                  {{ nameCountryPrice(data.value) }}
                </template>
                <template #cell(actions)="row">
                  <b-button variant="light" class="btn btn-sm" @click="openEditCountryArea(row.item, row.index)" v-b-modal.modal-newEdit-country-area>
                    <bui-icon name="pencil" class="m-0" size="18"/>
                  </b-button>
                  <b-button variant="light" class="btn btn-sm ml-1" @click="openDestroyCountryArea(row.item, row.index)" v-b-modal.modal-delete-country-area>
                    <bui-icon name="trash" class="m-0" size="18"/>
                  </b-button>
                </template>
              </b-table>
              <b-pagination v-if="countryAreaCode.length > 9" v-model="currentPage" :total-rows="rowsCountryArea" pills :per-page="perPage" aria-controls="my-table" align="right"></b-pagination>
            </div>
          </template>
      </div>
    </div>
  </bui-page>
</template>

<script>
  export default {
    data() {
      return {
        isAdmin: false,
        statistics: true,
        openCountry: false,
        openCountryAreaCode: false,
        search: null,
        type: 0,
        typeCountryArea: 0,
        perPage: 9,
        currentPage: 1,
        fieldsCountryPrice: [
          {key: 'description', label: this.$t('be-description-prices'), sortable: true},
          {key: 'code_iso', label: this.$t('be-code-prices'), sortable: true},
          {key: 'unit_price', label: this.$t('be-price-prices'), sortable: true},
          {key: 'code_number', label: this.$t('be-code-number')},
          {key: 'actions', label: ''}
        ],
        fieldsCountryArea: [
          {key: 'code_number', label: this.$t('be-code-number'), sortable: true},
          {key: 'area_code', label: this.$t('be-code-area'), sortable: true},
          {key: 'country_price_id', label: this.$t('be-modal-country-person'), sortable: true},
          {key: 'actions', label: ''}
        ],
        country: [],
        countryPrice: [],
        countryAreaCode: [],
        form: {
          idCountry: null,
          nameCountry: null,
          priceCountry: null,
          codeNumberCountry: null,
          codeIsoCountry: '',
        },
        formCountryArea: {
          idCountryArea: null,
          codeNumber: null,
          areaCode: null,
          countryPriceId: null
        }
      }
    },
    methods: {
      backHome() {
        this.openCountry = false,
        this.openCountryAreaCode = false,
        this.statistics = true;
      },
      searchCountry() {
        this.$axios
          .get('country_price?query=' + this.search)
          .then((response) => {
            if(response.data.data.length > 0)
            {
              if(response.status == 200)
              {
                this.countryPrice = response.data.data
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-search-country'));
              }
            }
            else
            {
              this.toast('danger',this.$t('be-t-error-search'));
            }
          })
          .catch(e => this.toast('danger',this.$t('be-t-error-search-country')));
      },
      nameCountryPrice(data) {
        for(var i = 0; i < this.country.length; i++)
        {
          if(this.country[i]['id'] == data)
          {
            return this.country[i]['description'];
          }
        }
      },
      newEditCountry() {
        var name = 0;
        var codeNumber = 0;
        var codeIso = 0;
        for(var i = 0; i < this.countryPrice.length; i++)
        {
          if(this.countryPrice[i]['description'] == this.form.nameCountry)
          {
            name = 1;
          }
          else if(this.countryPrice[i]['code_number'] == this.form.codeNumberCountry)
          {
            codeNumber = 1;
          }
          else if(this.countryPrice[i]['code_iso'] == this.form.codeIsoCountry)
          {
            codeIso = 1;
          }
        }
        if(!this.form.nameCountry || !this.form.priceCountry)
        {
          this.toast('warning',this.$t('be-t-warning-complete-fields'));
        }
        else
        {
          if(this.type == 1)
          {
            this.confirmEditCountry();
          }
          else
          {
            if(name == 1)
            {
              this.toast('warning',this.$t('be-t-exists-country'));
            }
            else if(codeNumber == 1)
            {
              this.toast('warning',this.$t('be-t-exists-code'));
            }
            else if(codeIso == 1)
            {
              this.toast('warning',this.$t('be-t-exists-initials'));
            }
            else
            {
              this.confirmNewCountry();
            }
          }
        }
      },
      newEditCountryArea() {
        if(!this.formCountryArea.codeNumber || !this.formCountryArea.areaCode || !this.formCountryArea.countryPriceId)
        {
          this.toast('warning',this.$t('be-t-warning-complete-fields'));
        }
        else
        {
          if(this.typeCountryArea == 1)
          {
            this.confirmEditCountryArea();
          }
          else
          {
            this.confirmNewCountryArea();
          }
        }
      },
      openEditCountryArea(data, index) {
        this.typeCountryArea = 1;
        this.formCountryArea.idCountryArea = data.id;
        this.formCountryArea.areaCode = data.area_code;
        this.formCountryArea.codeNumber = data.code_number;
        this.formCountryArea.countryPriceId = data.country_price_id;
      },
      openEditCountry(data, index) {
        this.type = 1;
        this.form.idCountry = data.id;
        this.form.nameCountry = data.description;
        this.form.priceCountry = data.unit_price.replace('USD $ ','');
        this.form.codeIsoCountry = data.code_iso;
        this.form.codeNumberCountry = data.code_number;
      },
      openDestroyCountryArea(data, index) {
        this.formCountryArea.idCountryArea = data.id;
        this.formCountryArea.areaCode = data.area_code;
      },
      openDestroyCountry(data, index) {
        this.form.idCountry = data.id;
        this.form.nameCountry = data.description;
      },
      openCountryPrice() {
        this.statistics = false;
        this.openCountry = true;
      },
      openCountryArea() {
        this.statistics = false;
        this.openCountryAreaCode = true;
      },
      confirmDeleteCountryArea() {
        this.$axios 
          .delete("country_area_code/" + this.formCountryArea.idCountryArea)
          .then((response) => {
            if(response.status == 200)
            {
              this.toast('success',this.$t('be-delete-success-register')),
              this.reset(),
              this.requestGetArea();
            }
          })
          .catch(e => this.toast('danger',this.$t('be-error-delete-register')));
      },
      confirmDeleteCountry() {
        this.$axios 
          .delete("country_price/" + this.form.idCountry)
          .then((response) => {
            if(response.status == 200)
            {
              this.toast('success',this.$t('be-t-success-delete-country')),
              this.reset(),
              this.requestGet();
            }
            else
            {
              this.toast('danger',this.$t('be-t-error-delete-country'));
            }
          })
          .catch(e => this.toast('danger',this.$t('be-t-error-delete-country')));
      },
      confirmNewCountryArea() {
        this.$axios
          .post('country_area_code', {
            area_code: this.formCountryArea.areaCode,
            code_number: this.formCountryArea.codeNumber,
            country_price_id: this.formCountryArea.countryPriceId
          })
          .then((response) => {
            if(response.status == 201)
            {
              this.toast('success',this.$t('be-success-create-register'));
              this.reset();
              this.requestGetArea();
            }
          })
          .catch(e => this.toast('danger',this.$t('be-error-create-register')));
      },
      confirmNewCountry() {
        this.$axios 
          .post('country-prices', {
              description: this.form.nameCountry,
              code_number: this.form.codeNumberCountry,
              code_iso: this.form.codeIsoCountry,
              unit_price: this.form.priceCountry
            })
          .then((response) => {
            if(response.status == 201)
            {
              this.toast('success',this.$t('be-t-success-create-country')),
              this.reset(),
              this.requestGet();
            }
            else
            {
              this.toast('danger',this.$t('be-t-error-create-country'));
            }
          })
          .catch(e => this.toast('danger',this.$t('be-t-error-create-country')));
      },
      confirmEditCountryArea() {
        this.$axios 
          .put("country_area_code/" + this.formCountryArea.idCountryArea, {
            area_code: this.formCountryArea.areaCode,
            code_number: this.formCountryArea.codeNumber,
            country_price_id: this.formCountryArea.countryPriceId
          })
          .then((response) => {
            if(response.status == 200)
            {
              this.toast('success',this.$t('be-success-update-register'));
              this.reset(),
              this.requestGetArea();
            }
          })
          .catch(e => this.toast('danger',e.response.data.message));
      },
      confirmEditCountry() {
          this.$axios 
            .put("country_price/" + this.form.idCountry, {
              description: this.form.nameCountry,
              code_number: this.form.codeNumberCountry,
              code_iso: this.form.codeIsoCountry,
              unit_price: this.form.priceCountry
            })
            .then((response) => {
              if(response.status == 200)
              {
                this.toast('success',this.$t('be-t-success-update-country')),
                this.reset(),
                this.requestGet();
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-update-country'));
              }
            })
            .catch(e =>this.toast('danger',this.$t('be-t-error-update-country')));
      },
      toast(variant,text) {
        this.$bvToast.toast(text, {
          variant: variant
        })
      },
      requestGet() {
        this.$axios
          .get("country_price")
          .then((response) => {this.countryPrice = response.data.data})
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-country')));
      },
      requestGetArea() {
        this.$axios
          .get("country_area_code")
          .then((response) => {this.countryAreaCode = response.data.data})
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-country')));
      },
      reset() {
        this.type = 0;
        this.typeCountryArea = 0;
        this.form.idCountry = null;
        this.form.nameCountry = null;
        this.form.priceCountry = null;
        this.form.codeNumberCountry = null;
        this.form.codeIsoCountry = '';
        this.formCountryArea.idCountryArea = null;
        this.formCountryArea.areaCode = null;
        this.formCountryArea.codeNumber = null;
        this.formCountryArea.countryPriceId = null;
      }
    },
    computed: {
      rowsCountryPrice() {
        return this.countryPrice.length
      },
      rowsCountryArea() {
        return this.countryAreaCode.length
      }
    },
    mounted() {
      if(this.$store.state.user.is_admin == 1)
      {
        this.isAdmin = true;
      }
      this.$axios
        .get("country_price")
        .then((response) => {this.countryPrice = response.data.data})
        .catch(e => this.toast('danger',this.$t('be-t-error-loading-country')));
      this.$axios
        .get("country_area_code")
        .then((response) => {this.countryAreaCode = response.data.data})
        .catch(e => this.toast('danger',this.$t('be-t-error-loading-country')));
      this.$axios
        .get("country_price")
        .then((response) => {this.country = response.data.data})
        .catch(e => this.toast('danger',this.$t('be-t-error-loading-country')));
    }
  }
</script>