<template>
  <bui-page>
    <template #page-header-left>
      <h1 class="bui-page-title">
        {{ $t('be-list-people-title') }}
      </h1>
    </template>
    <template #page-header-right>
      <b-input type="search" :placeholder="$t('ge-search')" class="mx-2" v-model="search" @keyup.enter="searchPeople()" @input="searchOnkeypress()" style="max-width: 200px"/>
      <b-button variant="primary" v-b-modal.modal-new-person @click="reset()">
        <bui-icon name="plus" variant="white" :size="18"/>
        {{ $t('be-new-person-btn') }}
      </b-button>
    </template>
    <div>
      <!-- Modal destroy pessoa -->
      <b-modal id="modal-delete-person" :title="$t('be-name')+ ': ' + this.person.name" centered no-fade ok-variant="danger" :ok-title="$t('ge-delete')" :cancel-title="$t('ge-cancel')" @ok="confirmDeletePerson()">
        <p class="my-4">{{ $t('be-question-delete-person') }}</p>
      </b-modal>
    </div>
    <div>
      <!-- Modal nova pessoa -->
      <b-modal id="modal-new-person" :title="$t('be-modal-register-person')" centered no-fade ok-variant="success" :ok-title="$t('ge-confirm')" :cancel-title="$t('ge-cancel')" @ok="newEditPerson()" @hide="reset()">
        <bui-form-group :label="$t('be-modal-name-person') + ':'" required>
          <b-form-input :state="!form.nameNewPerson? false : true" type="text" v-model="form.nameNewPerson" :placeholder="$t('be-modal-name-placeholder')"/>
        </bui-form-group>
        <bui-form-group :label="$t('be-modal-email-person') + ':'">
          <b-form-input type="email" v-model="form.emailNewPerson" :placeholder="$t('be-modal-email-placeholder')"/>
        </bui-form-group>
        <bui-form-group :label="$t('be-modal-country-person')" :help="$t('be-atention-country')" required>
          <b-form-select :state="!form.paisNewPerson? false : true" v-model="form.paisNewPerson" :options="country" class="mb-3" value-field="id" text-field="description"></b-form-select>
        </bui-form-group>
        <bui-form-group :label="$t('be-modal-phone-person') + ':'" :help="$t('be-atention-phone')" required>
          <b-form-input min="0" type="number" v-model="form.telefoneNewPerson" :state="stateTelefone" :placeholder="$t('be-modal-phone-placeholder')"/>
          <p class="float-right">{{ this.form.telefoneNewPerson.length + '/9-11' }}</p>
        </bui-form-group>
      </b-modal>
    </div>
    <template>
      <builderall-loader :loading="showTable == false">
        <b-table stacked="md" show-empty :empty-text="$t('be-create-your-person')" class="bg-white" hover :items="peoples" :fields="fields" :per-page="perPage" :current-page="currentPage">
          <template #cell(actions)="row">
            <b-button variant="light" class="btn btn-sm" @click="openEditPerson(row.item, row.index)" v-b-modal.modal-new-person>
              <bui-icon name="pencil" class="m-0" size="18"/>
            </b-button>
            <b-button variant="light" class="btn btn-sm ml-1" @click="openDestroyPerson(row.item, row.index)" v-b-modal.modal-delete-person>
              <bui-icon name="trash" class="m-0" size="18"/>
            </b-button>
          </template>
        </b-table>
      </builderall-loader>
    </template>
    <b-pagination v-if="rows > 9" v-model="currentPage" :total-rows="rows" pills :per-page="perPage" aria-controls="my-table" align="right"></b-pagination>
  </bui-page>
</template>

<script>
  export default {
    data() {
      return {  
        showTable: false,
        search: null,
        type: 0,
        person: [],
        perPage: 9,
        currentPage: 1,
        fields: [
          {key: 'name', label: this.$t('be-name'), sortable: true},
          {key: 'email', label: this.$t('be-modal-email-person')},
          {key: 'country_price.description', label: this.$t('be-modal-country-person')},
          {key: 'phone_number', label: this.$t('be-modal-phone-person')},
          {key: 'actions', label: ''}
        ],
        peoples: [],
        country: [],
        form: {
          idPerson: null,
          nameNewPerson: null,
          emailNewPerson: null,
          paisNewPerson: null,
          telefoneNewPerson: '',
        },
        searchTimer: null
      }
    },
    methods: {
      backHome() {
        this.requestGet();
        this.reset();
      },
      newEditPerson() {
        if(!this.form.nameNewPerson || !this.form.paisNewPerson || !this.form.telefoneNewPerson)
        {
          this.toast('warning',this.$t('be-t-warning-complete-fields'));
        }
        else if(this.form.telefoneNewPerson.length > 11)
        {
          this.toast('warning',this.$t('be-t-max-phone'));
        }
        else if(this.form.telefoneNewPerson.length < 9)
        {
          this.toast('warning',this.$t('be-t-min-phone'));
        }
        else
        {
          if(this.type == 1)
          {
            this.confirmEditPerson();
          }
          else
          {
            this.confirmNewPerson();
          }
        }
      },
      searchOnkeypress(){
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(this.searchPeople, 1000);
      },
      searchPeople() {
        this.showTable = false;
        this.currentPage = 1;
        this.$axios
        .get('person?query=' + this.search)
        .then((response) => {
          if(response.data.data.length > 0)
          {
            if(response.status == 200)
            {
              this.peoples = response.data.data
            }
            else
            {
              this.toast('danger',this.$t('be-t-error-search-person'));
            }
          }
          else
          {
            this.toast('danger',this.$t('be-t-error-search'));
          }
          this.showTable = true;
        })
        .catch(e => {this.showTable = true; this.toast('danger',this.$t('be-t-error-search-person'))});
    },
      openEditPerson(data, index) {
        this.type = 1;
        this.person = data;
        this.form.idPerson = data.id;
        this.form.nameNewPerson = data.name;
        this.form.emailNewPerson = data.email;
        this.form.paisNewPerson = data.country_price['id'];
        this.form.telefoneNewPerson = data.phone_number;
      },
      openDestroyPerson(data, index) {
        this.person = data;
      },
      confirmNewPerson() {
        this.$axios 
          .post("person", {
              name: this.form.nameNewPerson,
              email: this.form.emailNewPerson,
              country_price_id: this.form.paisNewPerson ,
              phone_number: this.form.telefoneNewPerson
            })
          .then((response) => {
            if(response.status == 201)
            {
              this.toast('success',this.$t('be-t-success-add-person')),
              this.backHome();
            }
            else
            {
              this.toast('danger',this.$t('be-t-error-add-person'));
            }
          })
          .catch(e => this.toast('danger',this.$t('be-t-error-add-person')));
      },
      confirmEditPerson() {
        this.$axios 
            .put("person/" + this.form.idPerson, {
              name: this.form.nameNewPerson,
              email: this.form.emailNewPerson,
              country_price_id: this.form.paisNewPerson,
              phone_number: this.form.telefoneNewPerson
            })
            .then((response) => {
              if(response.status == 200)
              {
                this.toast('success',this.$t('be-t-success-edit-person')),
                this.backHome();
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-edit-person'));
              }
            })
            .catch(e => this.toast('danger',this.$t('be-t-error-edit-person')));
      },
      confirmDeletePerson() {
        this.$axios 
            .delete("person/" + this.person['id'])
            .then((response) => {
              if(response.status == 200)
              {
                this.toast('success',this.$t('be-t-success-remove-person')),
                this.backHome();
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-delete-person'));
              }
            })
            .catch(e => {
              if(e.response.data.qtt_category > 0)
              {
                this.toast('danger',this.$t('be-t-error-person-category') + ' ' + e.response.data.qtt_category + ' ' + this.$t('be-category-s'));
              }
              else if(e.response.data.qtt_messages > 0)
              {
                this.toast('danger',this.$t('be-t-has-message') + ' ' + e.response.data.qtt_messages + ' ' + this.$t('be-t-message-s'));
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-delete-person'));
              }
            });
      },
      requestGet() {
        this.$axios
          .get("person")
          .then((response) => {this.peoples = response.data.data})
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-peoples')));
      },
      toast(variant,text) {
        this.$bvToast.toast(text, {
          variant: variant
        })
      },
      reset() {
        this.type = 0;
        this.person = [];
        this.form.idPerson = null;
        this.form.nameNewPerson = null;
        this.form.emailNewPerson = null;
        this.form.paisNewPerson = null;
        this.form.telefoneNewPerson = '';
      }
    },
    computed: {
      rows() {
        return this.peoples.length
      },
      stateTelefone() {
        if(this.form.telefoneNewPerson.length > 8 && this.form.telefoneNewPerson.length < 12)
        {
          return true;
        }
        else
        {
          return false;
        }
      },
      stateEmail() {
        if(!this.form.emailNewPerson)
        {
          return false;
        }
        else if(this.form.emailNewPerson.indexOf("@") == -1)
        {
          return false;
        }
        else if(this.form.emailNewPerson.indexOf("@") + 1 >= this.form.emailNewPerson.length)
        {
          return false;
        }
        else
        {
          return true;
        }
      }
    },
    watch: {
      search(newValue, oldValue) {
        if(newValue == '')
        {
          clearTimeout(this.searchTimer);
          this.searchPeople();
        }
      }
    },
    mounted() {
      this.$axios
          .get("country_price")
          .then((response) => {this.country = response.data.data})
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-country'))); 
      this.$axios
          .get("person")
          .then((response) => {this.showTable = true; this.peoples = response.data.data})
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-peoples'))); 
    }
  }
</script>