import Cookie from 'js-cookie'

const TOKEN_NAME = '__ba_sms_token'
const EXPIRES = 30

export default {
  setToken (token) {
    Cookie.set(TOKEN_NAME, token, { expires: EXPIRES })
  },

  getToken () {
    return Cookie.get(TOKEN_NAME)
  },

  deleteToken () {
    Cookie.remove(TOKEN_NAME)
  }
}
