<template>
    <div v-if="showAlert" class='ba-alert ba-error'>
        <span>
            {{ $t('be-alert-to-send-sms') }}.
            <b-link to="/data-user?page=a2p" class="ba-text-uppercase ba-montserrat-bold">{{ $t('ge-click-here') }}</b-link>
        </span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showAlert: false,
        }
    },
    created() {
        this.getAlertStatus();
    },
    methods: {
        getAlertStatus(){
            const email = localStorage.getItem('smsuseremail')
            this.$axios
            .post('verify-a2p10dlc', {
                email: email,
                origin: 'dashboard'
            })
            .then((response) => {
                this.showAlert = false;
            })
            .catch(e => {
                this.showAlert = true;
            }) 
        },
    }
}
</script>

<style lang="scss" scoped>
    .ba-alert{
        a{
            color: inherit;
            &:hover{
                color: black;
            }
        }
    }
</style>