import Vue from 'vue'
import VueRouter from 'vue-router'

import LayoutDefault from '../components/layouts/LayoutDefault.vue'
import Guard from '@/service/middleware'
import Categories from '../views/Categories.vue'
import Peoples from '../views/Peoples.vue'
import Prices from '../views/Prices.vue'
import Submit from '../views/Submit.vue'
import Historic from '../views/Historic.vue'
import Admin from '../views/Admin.vue'
import Import from '../views/Import.vue'
import Auth from '../views/Auth.vue'
import DataUser from '../views/DataUser.vue'
import Tutorials from '../views/Tutorials.vue'

// Office 6
import Dashboard from '../views/office6/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth/:token',
    name: 'Auth',
    component: Auth,
    meta: {title: 'Auth | Builderall'}
  },
  {
    path: '/',
    component: LayoutDefault,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {title: 'SMS | Builderall'}
      },
      {
        path: '/categories',
        name: 'Categories',
        component: Categories,
        meta: {title: 'My Groups | Builderall'}
      },
      {
        path: '/peoples',
        name: 'Peoples',
        component: Peoples,
        meta: {title: 'My Contacts | Builderall'}
      },
      {
        path: '/import-list',
        name: 'Import',
        component: Import,
        meta: {title: 'SMS | Builderall'}
      },
      {
        path: '/prices',
        name: 'Prices',
        component: Prices,
        meta: {title: 'Pricing Table | Builderall'}
      },
      {
        path: '/submit-sms',
        name: 'Submit',
        component: Submit,
        meta: {title: 'Send SMS | Builderall'}
      },
      {
        path: '/historic',
        name: 'Historic',
        component: Historic,
        meta: {title: 'Sent Messages | Builderall'}
      },
      {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {title: 'Admin | Builderall'}
      },
      {
        path: '/data-user',
        name: 'DataUser',
        component: DataUser,
        meta: {title: 'Integrations | Builderall'}
      },
      {
        path: '/tutorials',
        name: 'Tutorials',
        component: Tutorials,
        meta: {title: 'Tutorials | Builderall'}
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('smstoken');
  let wrapper = document.querySelector('.sms-wrapper-element');
  wrapper.setAttribute('token',token);
  document.title = to.meta.title || 'Send SMS | Builderall';
  next();
});

export default router
