<template>
  <div v-if="!token" class="all text-center">
    <h1 class="text-primary">SMS - Builderall</h1>
    <br>
    <button class="btn btn-primary" @click="redirectToOffice()">Enter</button>
    <br>
    {{ this.token }}
  </div>
</template>
<script>
import Cookie from '@/service/cookie' 
export default {
  data() {
    return {
      token: null,
      redir: null,
      a2p: false,
      routes: ['Home','Categories','Peoples','Import','Prices','Submit','Historic','Admin','DataUser','Tutorials']
    }
  },
  methods: {
    login(redir) {
      this.$axios
        .post('auth-office', {token: this.token})
        .then((response) => { 
          const token = `${response.data.token.token_type} ${response.data.token.access_token}`;
          
          // define token in cookie
          Cookie.setToken(token);
          
          // define language -> response.data.user.locale;
          let locale = response.data.user.locale;

          localStorage.setItem('smslocale', locale);
          localStorage.setItem('smsuseremail', response.data.user.email);
          this.$i18n.locale = locale;

          if(!redir == false && this.routes.find(x => x == redir))
          {
            this.$router.push({ name: redir });
          }
          else if(!redir == false && redir == 'a2p')
          {
            this.$router.push({ path: '/data-user?page=a2p' });
          }
          else if(response.data.twilio_exists)
          {
            // go to home
            this.$router.push({ name: 'Dashboard' });
          }
          else
          {
            // go to twilio data
            this.$router.push({ name: 'DataUser' });
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    redirectToOffice() {
      let urlOk = document.URL;
      let url = '';

	  //console.log('Url: ' + urlOk);
      if(urlOk.indexOf('sms-front.kub.app.builderall.info') != -1)
      {
        // homolog
        url = "https://office.kub.app.builderall.info/us/office/sms";
      }
      else if(urlOk.indexOf('sms-front.kub.app.builderall.io') != -1)
      {
        // test
        url = "https://office.kub.app.builderall.io/us/office/sms";
      }
      else
      {
        // prod or local
        url = "https://office.kub.app.builderall.com/us/office/sms";
      }
      location.href = url;
    },
  },
  created() {
    this.token = this.$route.params.token;
    this.redir = this.$route.query.redir;
    this.a2p = this.$route.query.page;
    localStorage.setItem('smstoken', this.token);
    let wrapper = document.querySelector('.sms-wrapper-element');
    wrapper.setAttribute('token',this.token);
    if(this.token)
    {
      this.login(this.redir == undefined ? this.a2p : this.redir);
    }
  }
}
</script>


<style lang="scss" scoped>
  .all{
    margin-top: 230px;
    z-index: 6;
    position: relative;
  }
</style>