<template>
    <bui-page class="ba-layout">
        <builderall-loader :loading="cards[0].status || cards[1].status || cards[1].status" class='ba-card ba-card-grid' style="height: auto;">
            <div class='ba-top'>
                <span class='ba-title' v-text="$t('be-activity')"></span>
            </div>
            <div class='ba-content'>
                <div class='ba-card ba-statistics ba-300'>
                    <div class='ba-top'>
                        <span class='ba-icon' v-html="cards[0].icon"></span>
                        <span class='ba-title' v-text="cards[0].title"></span>
                    </div>
                    <div class='ba-content ba-jc-fs'>
                        <span class='ba-data' v-text="!cards[0].symbol ? cards[0].data : cards[0].symbol + cards[0].data.toFixed(2)"></span>
                    </div>
                    <div class='ba-bottom'>
                        <b-link :to="cards[0].link" class="link-action">
                            {{cards[0].linkTitle}}
                        </b-link>
                    </div>
                </div>
                <hr class='ba-hr-column'>
                <div class='ba-card ba-statistics ba-300'>
                    <div class='ba-top'>
                        <span class='ba-icon' v-html="cards[1].icon"></span>
                        <span class='ba-title' v-text="cards[1].title"></span>
                    </div>
                    <div class='ba-content ba-jc-fs'>
                        <span class='ba-data' v-text="!cards[1].symbol ? cards[1].data : cards[1].symbol + cards[1].data.toFixed(2)"></span>
                    </div>
                    <div class='ba-bottom'>
                        <b-link :to="cards[1].link" class="link-action">
                            {{cards[1].linkTitle}}
                        </b-link>
                    </div>
                </div>
                <hr class='ba-hr-column'>
                <div class='ba-card ba-statistics ba-300'>
                    <div class='ba-top'>
                        <span class='ba-icon' v-html="cards[2].icon"></span>
                        <span class='ba-title' v-text="cards[2].title"></span>
                    </div>
                    <div class='ba-content ba-jc-fs'>
                        <span class='ba-data' v-text="!cards[2].symbol ? cards[2].data : cards[2].symbol + cards[2].data.toFixed(2)"></span>
                    </div>
                    <div class='ba-bottom'>
                        <b-link :to="cards[2].link" class="link-action">
                            {{cards[2].linkTitle}}
                        </b-link>
                    </div>
                </div>
            </div>
        </builderall-loader>
        <builderall-loader :loading="showTableSpinner" class='ba-card ba-w-100'>
            <div class='ba-top'>
                <span class='ba-title' v-text="$t('be-sent-messages')"></span>
                <div class='ba-box-items ba-flex ba-gp-1'>
                    <div class='ba-box-tabs'>
                        <a 
                            v-for="(t,i) in tabs" :key="i"
                            @click="setNewInterval(t.data)" 
                            :class="{'ba-tab': true, 'ba-is-active': interval == t.data}"
                            v-text="t.title"
                        ></a>
                    </div>
                    <b-link to="/submit-sms" class='ba-btn ba-md ba-blue'>
                        <builderall-icon code='2780'></builderall-icon> 
                        {{ $t('be-new-message') }}
                    </b-link>
                </div>
            </div>
            <div class='ba-content ba-w-100'>
                <table v-if="!showTableSpinner" class='ba-table ba-md ba-lines ba-w-100'>
                    <thead>
                        <tr>
                            <th v-for="(t,i) in tableHead" :key="i" v-text="t.title"></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(t,i) in tableData" :key="i">
                            <td style="text-align: center;">
                                <span 
                                    ba-tooltip-color="dark"
                                    ba-tooltip-position='left' 
                                    :ba-tooltip-title="isSubmited(t) ? $t('be-t-success-submit-sms') : $t('be-t-error-submit-sms')"
                                    :class="['ba-circle', isSubmited(t) ? 'ba-green' : 'ba-red']"
                                ></span>
                            </td>
                            <td v-text="t.phone_number"></td>
                            <td v-text="t.description"></td>
                            <td class='ba-double'>
                                <div class="ba-title" v-text="formatDate(t.created_at, 'date')"></div>
                                <div class="ba-subtitle" v-text="formatDate(t.created_at, 'time')"></div>
                            </td>
                            <td v-text="t.unit_price"></td>
                            <td v-text="t.origin"></td>
                            <td style="text-align: right;">
                                <button @click="showModalDetails(t)" class="ba-btn ba-sm ba-rounded ba-btn-normal">
                                    <builderall-icon code="eye" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="tableData.length < 1 && !showTableSpinner" class="ba-flex ba-no-recors-found ba-jc-center ba-pd-1">
                    <span>{{ $t('ge-no-records-found') }}</span>
                </div>
                
            </div>
        </builderall-loader>
        <b-modal v-model="showModalDetailsSubmit" centered no-fade hide-footer hide-header-close>
            <div class="ba-card ba-w-100">
                <div class='ba-top'>
                    <span class='ba-title' v-text="$t('be-details-submit')"></span>
                    <div class='ba-box-items ba-flex ba-gp-1'>
                        <button class='ba-btn ba-btn-close ba-sm ba-rounded' @click="showModalDetailsSubmit = false">
                            <builderall-icon code='64f7' />
                        </button>
                    </div>
                </div>
                <div class='ba-content ba-w-100'>
                    <div class="view-details-modal ba-w-100 ba-flex ba-f-column ba-h-100 ba-bg-blue18 ba-pd-1 ba-br ba-gp-1">
                        <div class="creation-date ba-flex ba-f-column ba-">
                            <p>{{ $t('be-date-create')}}</p>
                            <div class="ba-flex ba-text-center ba-w-100 ba-gp-1">
                                <img src="../../../public/calendar.svg" alt="">
                                <h6 class="ba-montserrat-400 date-create">{{ mlDateCreate }}</h6> 
                            </div>
                        </div>
                        <div class="ba6-us-main-horizontal-line" />
                        <div class="country-price ba-flex ba-jc-sb ba-mg-r-2">
                            <div class="ba-flex ba-f-column">
                                <p>{{ $t('be-modal-country-person') }}</p>
                                <div class="ba-flex ba-ai-center ba-gp-04">
                                    <img class="country-code-img" :src="mlCountryCode" alt="">
                                    {{ mlCountry }}
                                </div>
                            </div>
                            <div class="ba-flex ba-f-column">
                                <p>{{ $t('ge-price') }}</p>
                                <div class="ba-flex ba-text-center ba-w-100 ba-gp-1">
                                    <img src="../../../public/calendar.svg" alt="">
                                    <h6 class="ba-montserrat-400 date-create"> USD $ {{ mlPrice }}</h6> 
                                </div>
                            </div>
                        </div>
                        <div class="ba6-us-main-horizontal-line" />
                        <div class="message ba-w-100">
                            <div class="ba-flex ba-f-column ba-w-100">
                            <p>{{ $t('be-message') }}</p>
                            <div class="ba-w-100 ba-bg-white1 ba-w-100 ba-h-100 box">
                                <h6 class="ba-montserrat-400 date-create ba-mg-l-1 ba-mg-r-1"> {{ mlMsg }} </h6>
                            </div>
                        </div>
                        </div>
                        <div class="origin-status ba-flex ba-w-100 ba-jc-sb ba-gp-1">
                            <div class="ba-flex ba-f-column">
                                <p>{{ $t('be-origin-relatory') }}</p>
                                <div class="ba-flex ba-gp-04 ba-ai-center">
                                    <builderall-icon :code="showOriginIcon(mlOrigin)"></builderall-icon> 
                                    <h6 class="ba-montserrat-400 date-create">{{ mlOrigin }}</h6> 
                                </div>
                            </div>
                            <div class="ba-flex ba-f-column ba-mg-r-3">
                                <p>{{ $t('be-status-relatory') }}</p>
                                <div class="ba-flex ba-gp-1">
                                    <h6 class="ba-montserrat-400 date-create">{{ mlStatus }}</h6> 
                                </div>
                            </div>
                        </div>
                        <div v-if="mlNameRecipient" class="ba6-us-main-horizontal-line" />
                        <div v-if="mlNameRecipient" class="recipients-name">
                            <p>{{ $t('be-name-recipient') }}</p>
                            <h6 class="ba-montserrat-400 date-create"> {{ mlNameRecipient }} </h6>
                        </div>
                        <div class="ba6-us-main-horizontal-line" />
                        <div class="phone">
                            <p>{{ $t('be-for-phone') }}</p>
                            <h6 class="ba-montserrat-400 date-create"> {{ mlPhone }} </h6>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </bui-page>
</template>

<script>

export default {
    data() {
        return {
            tableData: [],
            showTableSpinner: true,
            showModalDetailsSubmit: false,
            cards: [
                {
                    icon: "<builderall-icon code='6d01' />",
                    title: this.$t('be-peoples'),
                    data: 0,
                    linkTitle: this.$t('ge-view-all'),
                    link: '/peoples',
                    status: true
                },
                {
                    icon: "<builderall-icon code='56ba' />",
                    title: this.$t('be-groups'),
                    data: 0,
                    linkTitle: this.$t('ge-view-all'),
                    link: '/categories',
                    status: true
                },
                {
                    icon: "<builderall-icon code='e35b'></builderall-icon>",
                    title: this.$t('be-twilio-balance'),
                    symbol: 'USD $ ',
                    data: 0,
                    linkTitle: this.$t('ge-settings'),
                    link: '/data-user',
                    status: true
                },
            ],
            interval: 7,
            tabs: [
                {
                    title: this.$t('ge-seven-days'), 
                    data: 7
                },
                {
                    title: this.$t('ge-30-days'), 
                    data: 30
                },
                {
                    title: this.$t('ge-all-time'), 
                    data: 0
                }
            ],
            tableHead: [
                {title: this.$t('ge-status')},
                {title: this.$t('ge-phone')},
                {title: this.$t('ge-country')},
                {title: this.$t('ge-date')},
                {title: this.$t('be-cost-per-sms')},
                {title: this.$t('be-origin-relatory')}
            ],
            mlDateCreate: '',
            mlPhone: '',
            mlMsg: '',
            mlOrigin: '',
            mlStatus: '',
            mlPrice: '',
            mlCountry: '',
            mlCountryCode: '',
            mlNameRecipient: '',
        }
    },
    created() {
        this.getCountContacts();
        this.getCountGroups();
        this.getAllData();
        this.getTwilioBalance();
    },
    methods: {
        showOriginIcon(data){
            if(data == "booking")
            {
                return "booking";
            }
            else if(data = "mailingboss")
            {
                return "email-marketing-5";
            }
            else if(data == "chatbot")
            {
                return "website-chatbot";
            }
            else
            {
                return "sms";
            }
        },
        formatDate(data, type) {
            let date = new Date(data);
            if(type == 'time')
            {
                return date.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: '2-digit' });
            }
            let year = date.getFullYear();
            let months = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'];
            let month = months[date.getMonth()];
            let day = date.getDate();
            return month + ' ' + day + ', ' + year;
        },
        showModalDetails(data) {
            this.showModalDetailsSubmit = true;
            this.mlDateCreate = data.created_at;
            this.mlPhone = data.phone_number;
            this.mlMsg = data.message;
            this.mlOrigin = data.origin;
            this.mlStatus = data.status;
            this.mlPrice = data.unit_price;
            this.mlCountry = data.description;
            this.mlNameRecipient = data.name;
            const countryCode = data.code_iso.toLowerCase();
            this.mlCountryCode = require(`../../assets/flags/${countryCode}.png`),
            console.log(this.mlCountryCode, data.code_iso, countryCode, data);
        },
        getCountContacts() {
            this.cards[0]['status'] = true;
            this.$axios
                .get("person/total")
                .then((response) => {
                    this.cards[0]['data'] = response.data.qttPersons;
                })
                .catch(e => {
                    console.log(e);
                    this.toast('warning',this.$t('be-error-get-data'));
                })
                .finally(() => {
                    this.cards[0]['status'] = false;
                })
        },
        getCountGroups() {
            this.cards[1]['status'] = true;
            this.$axios
                .get("category/total")
                .then((response) => {
                    this.cards[1]['data'] = response.data.qttCategories;
                })
                .catch(e => {
                    console.log(e);
                    this.toast('warning',this.$t('be-error-get-data'));
                })
                .finally(() => {
                    this.cards[1]['status'] = false;
                })
        },
        getTwilioBalance() {
            this.cards[2]['status'] = true;
            this.$axios
                .get('balance')
                .then((response) => { 
                if(response.status == 200)
                {
                    this.cards[2]['data'] += parseFloat(response.data.data.balance);
                    this.$axios
                    .get('get-balance-from-twilio')
                    .then((response) => { 
                        if(!response.data.balance)
                        {
                            this.cards[2]['data'] = 0;
                        }
                        else
                        {
                            this.cards[2]['data'] += parseFloat(response.data.balance);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => { 
                        this.cards[2]['status'] = false;
                    })
                }
                })
                .catch(e => {
                    console.log(e);
                    this.toast('warning',this.$t('be-error-get-data'));
                })
        },
        getAllData() {
            this.showTableSpinner = true;
            this.$axios
                .get("messages-by-user?qtty_days="+this.interval)
                .then((response) => {
                    this.tableData = response.data.messages.result;
                })
                .catch(e => {
                    console.log(e);
                    this.toast('warning',this.$t('be-error-get-data'));
                })
                .finally(() => {
                    this.showTableSpinner = false;
                })
        },
        setNewInterval(data){
            this.interval = data;
            this.getAllData();
        },
        toast(variant,text) {
            this.$bvToast.toast(text, {
                variant: variant
            })
        },
        isSubmited(t){
            return t.status == 'delivered' || t.status == 'sent'
        }
    }
}
</script>

<style lang="scss" scoped>
    .country-code-img{
        width: 24px;
        border-radius: 3px;
    }
    builderall-icon{
        --builderall-icon-color: #872BFD;
    }
                  
    .box{
        border-radius: 16px;
    }
    .ba6-us-main-horizontal-line {
        background-color: #c4d1e0;
        width: 100%;
        height: 1px;
    }
    .date-create{
        color: #6683BC;
        margin: none;
    }
    .ba-br{
        border-radius: 16px;
    }
    .ba-table{
        tbody{
            tr{
                td:is(:nth-last-child(2),:last-child)::after{
                    border-right: none !important;
                }
                .ba-double{
                    .ba-title,
                    .ba-subtitle{
                        font-size: 13px !important;
                    }
                }
            }
        }
    }
    .ba-bottom{
        display: flex;
        justify-content: flex-end !important;
        .link-action{
            font-size: 12px;
            .ba-icon{
                builderall-icon{
                    --builderall-icon-size: 15px;
                    --builderall-icon-color: #0072E1;
                }
                margin-left: .4rem;
            }
        }
    }
    .btn-eye{
        svg{
            color: #8E9EB5;
        }
        &:hover{
            background-color: #0072E1;
            transition: .4s;
            svg{
                color: #fff;
            }
        }
    }
    .ba-no-recors-found{
        height: 8rem;
    }
    h6{
        margin-bottom: 0;
    }
    header{
        display: none !important;
    }
    .modal-content{
       padding: 0 !important; 
    }
</style>

<style lang="scss">
    .modal-content:has(.view-details-modal){
        padding: 0 !important;
        .modal-body{
            padding: 0 !important;
        }
        .ba-card{
            .ba-title{
                font-size: 22px;
            }
            .view-details-modal{
                background-color: #fff;
            }
        }
    }
</style>