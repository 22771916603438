<template>
  <bui-page>
    <template v-if="this.list" #page-header-left>
      <h1 class="bui-page-title">
        {{ $t('be-list-categories') }}
      </h1>
    </template>
    <template v-if="this.list" #page-header-right>
      <b-input type="search" :placeholder="$t('ge-search')" v-model="search" @keyup.enter="searchCategory()" @input="searchOnkeypress()" class="mx-2" style="max-width: 200px"/>
      <div>
        <b-button @click="reset()" v-b-modal.modal-newCategory variant="primary h-100">
          <bui-icon name="plus" variant="white" size="18"/>
          {{ $t('be-new-category-btn') }}
        </b-button>
      </div>
    </template>
    <builderall-loader :loading="showTable == false">
      <p v-if="this.cards.length == 0" class="text-center p-5">{{ $t('be-you-can-send-for-category') }} <b>{{ $t('be-create-your-categories') }}</b></p>
      <div class="mt-5">
        <b-card-group deck>
          <div v-for="(card,i) in cards" :key="i" id="cardItems">
            <b-card @click="enterCategory(card)" id="card" border-variant="primary" :header="$t('ge-enter')" header-bg-variant="primary" header-text-variant="white" align="center">
              <b-card-text class="text-primary h4"><b>{{ card.description }}</b></b-card-text>
            </b-card>
            <br>
          </div>
        </b-card-group>
      </div>
    </builderall-loader>
    <div>
      <!-- Modal nova categoria -->
      <b-modal id="modal-newCategory" :title="$t('be-modal-title-category')" centered no-fade ok-variant="success" :ok-title="$t('ge-confirm')" :cancel-title="$t('ge-cancel')" @ok="newCategory()" @hide="calcelNewCategory()">
        <bui-form-group :label="$t('be-name') + ': '" :help="$t('be-help-create-category')" required>
          <b-form-input :state="!nameNewCategory ? false : true" type="text" v-model="nameNewCategory" :placeholder="$t('be-modal-placeholder-category')" max="50"/>
        </bui-form-group>
      </b-modal>
    </div>
    <div>
      <!-- Modal de edição das categorias -->
      <b-modal id="edit-category" :title="$t('be-category') + ': ' + this.nameEditCategory" centered no-fade ok-variant="success" :ok-title="$t('ge-confirm')" :cancel-title="$t('ge-cancel')" @hide="calcelEditCategory()" @ok="confirmEditCategory()">
        <bui-form-group :label="$t('be-name') + ': '" required>
          <b-form-input :state="!nameEditCategory ? false : true" required type="text" v-model="nameEditCategory" :placeholder="$t('be-modal-placeholder-category')"/>
        </bui-form-group>
      </b-modal>
    </div>
    <div>
      <!-- Modal de deleção de categorias -->
      <b-modal id="delete-category" :title="$t('be-category') + ': ' + this.category.description" centered no-fade ok-variant="danger" :ok-title="$t('ge-delete')" :cancel-title="$t('ge-cancel')" @ok="confirmDeleteCategory()">
        <p class="my-4">{{ $t('be-question-delete-category') }}</p>
      </b-modal>
    </div>
    <template v-if="this.edit">
      <div class="row">
        <div class="col-sm-6">
          <h1 class="bui-page-title">{{ $t('be-category') }}: <u>{{ this.category.description }}</u></h1>
        </div>
        <div class="col-sm-6">
          <b-button class="mr-2" v-b-modal.edit-category variant="primary" :title="$t('ge-edit')">{{ $t('ge-edit') }} 
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ml-1 bi bi-pencil-fill" viewBox="0 0 16 16">
              <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
            </svg>
          </b-button>
          <b-button class="mr-2" v-b-modal.delete-category variant="danger" :title="$t('ge-delete')">{{ $t('ge-delete') }}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
          </b-button>
          <b-button variant="light" @click="backHome()" :title="$t('ge-back')">{{ $t('ge-back') }}</b-button>
        </div>
      </div>
      <br>
      <h1 class="bui-page-title">{{ $t('be-users-category') }}({{ peoplesCategory.length }}):</h1>
      <div id="tableRemovePerson">
        <b-table show-empty :empty-text="showTableCategory == 1 ? $t('be-add-person-in-category') : ($t('ge-load') + '...')" class="bg-white text-center" hover :items="peoplesCategory" :fields="fieldsPeopleCategory">
          <template #cell(actions)="row">
            <bui-form-group>
              <svg @click="removePerson(row)" xmlns="http://www.w3.org/2000/svg" id="removePerson" width="16" height="16" fill="currentColor" class="bi bi-dash-lg" viewBox="0 0 16 16">
                <path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"/>
              </svg>
            </bui-form-group>
          </template>
        </b-table>
      </div>
      <h1 class="bui-page-title">{{ $t('be-all-users') }}:</h1>
      <div id="tableAddPerson">
        <b-table show-empty :empty-text="showTableAll == 1 ?  (peoplesCategory.length > 0 ? $t('be-all-people-in-category') : $t('be-no-one-person-in-category')) : ($t('ge-load') + '...')" class="bg-white text-center" hover :items="allPeoples" :fields="fieldsAllPeople">
          <template #cell(actions)="row">
            <bui-form-group>
              <svg @click="addPerson(row)" xmlns="http://www.w3.org/2000/svg" id="addPerson" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </bui-form-group>
          </template>
        </b-table>
      </div>
    </template>
  </bui-page>
</template>

<script>
  export default {
    data() {
      return {  
        showTable: false,
        showTableCategory: 0,
        showTableAll: 0,
        list: true,
        edit: false,
        search: null,
        successApiNumber: 200,
        nameEditCategory: null,
        category: [],
        fieldsPeopleCategory: [
          {key: 'name', label: this.$t('be-name'), sortable: true},
          {key: 'actions', label: this.$t('be-remove-person')},
        ],
        peoplesCategory: [],
        fieldsAllPeople: [
          {key: 'name', label: this.$t('be-name'), sortable: true},
          {key: 'actions', label: this.$t('be-add-person')},
        ],
        allPeoples: [],
        nameNewCategory: null,
        cards: [],
        searchTimer: null
      }
    },
    methods: {
      backHome() {
        this.edit = false;
        this.list = true;
        this.requestGet();
        this.reset();
      },
      newCategory() {
        if(this.nameNewCategory == null || this.nameNewCategory == '')
        {
          this.toast('warning',this.$t('be-t-warning-complete-fields'));
        }
        else if(this.nameNewCategory.length > 49)
        {
          this.toast('danger',this.$t('be-t-error-length-fifty'));
        }
        else
        {
          this.$axios 
            .post("category", {description: this.nameNewCategory})
            .then((response) => {
              if(response.status == 201)
              {
                this.toast('success',this.$t('be-t-success-create-category')),
                this.backHome();
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-create-category'));
              }
            })
            .catch(e => this.toast('danger',this.$t('be-t-error-create-category')));
        }
      },
      calcelNewCategory() {
        this.nameNewCategory = null;
      },
      toast(variant,text) {
        this.$bvToast.toast(text, {
          variant: variant
        })
      },
      addPerson(data) {
        this.$axios 
          .post("category_person?category_id="+ this.category.id +"&person_id="+data.item.id)
          .then((response) => {
            if(response.status == 201)
            {
              this.enterCategory(this.category),
              this.toast('success',this.$t('be-t-success-add-person'));
            }
            else
            {
              this.toast('danger',this.$t('be-t-error-add-person'));
            }
          })
          .catch(e => this.toast('danger',this.$t('be-t-error-add-person')));
      },
      removePerson(data) {
        this.$axios 
          .delete("category_person/"+ this.category.id +"/"+data.item.id)
          .then((response) => {
            if(response.status == 200)
            {
              this.enterCategory(this.category),
              this.toast('success',this.$t('be-t-success-remove-person'));
            }
            else
            {
              this.toast('danger',this.$t('be-t-error-remove-person'));
            }
          })
          .catch(e => this.toast('danger',this.$t('be-t-error-remove-person')));
      },
      confirmDeleteCategory() {
        this.$axios 
            .delete("category/"+this.category.id)
            .then((response) => {
              if(response.status == 200)
              {
                this.toast('success',this.$t('be-t-success-delete-category')),
                this.backHome();
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-delete-category'));
              }
            })
            .catch(e => {
              if(e.response.data.qtt_person > 0)
              {
                this.toast('danger',this.$t('be-t-error-category-person') + ' ' + e.response.data.qtt_person + ' ' + this.$t('be-person-s'));
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-delete-category'));
              }
            });
      },
      requestGet() {
        this.$axios
          .get("category")
          .then((response) => {this.cards = response.data.data})
          .catch(e => this.toast('danger',this.$t('be-t-error-loading-category')));
      },
      enterCategory(data) {
        this.category = data;
        this.$axios
          .get('persons-belongs-to-category?category_id='+data.id+'&belongs=0')
          .then((response) => {this.showTableAll = 1; this.allPeoples = response.data})
          .catch(e => {this.showTableAll = 1; this.toast('danger',this.$t('be-t-error-loading-category'))});
        this.$axios
          .get('persons-belongs-to-category?category_id='+data.id+'&belongs=1')
          .then((response) => {this.showTableCategory = 1; this.peoplesCategory = response.data})
          .catch(e => {this.showTableCategory = 1; this.toast('danger',this.$t('be-t-error-loading-category'))});
        this.nameEditCategory = data.description;
        this.list = false;
        this.edit = true;
      },
      calcelEditCategory() {
        this.nameEditCategory = null;
        this.nameEditCategory = this.category.description;
      },
      searchOnkeypress(){
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(this.searchCategory, 1000);
      },
      searchCategory() {
        this.showTable = false;
        this.$axios
          .get("category?query="+this.search)
          .then((response) => {
            if(response.data.data.length > 0)
            {
              if(response.status == 200)
              {
                this.cards = response.data.data
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-search-category'));
              }
            }
            else
            {
                this.toast('danger',this.$t('be-t-error-search'));
            }
            this.showTable = true;
          })
          .catch(e => {this.showTable = true; this.toast('danger',this.$t('be-t-error-search-category'))});
      },
      confirmEditCategory() {
        if(this.nameEditCategory == null || this.nameEditCategory == '')
        {
          this.toast('danger', this.$t('be-t-enter-name-category'));
        }
        else if(this.nameEditCategory.length > 49)
        {
          this.toast('danger',this.$t('be-t-error-length-fifty'));
        }
        else
        {
          this.$axios 
            .put("category/"+this.category.id, {description: this.nameEditCategory})
            .then((response) => {
              if(response.status == 200)
              {
                this.toast('success',this.$t('be-t-succes-edit-category')),
                this.backHome();
              }
              else
              {
                this.toast('danger',this.$t('be-t-error-edit-category'));
              }
            })
            .catch(e => this.toast('danger',this.$t('be-t-error-edit-category')));
        }
      },
      reset() {
        this.nameNewCategory = null;
        this.nameEditCategory = null;
        this.showTableCategory = 0;
        this.showTableAll = 0;
        this.peoplesCategory = [];
        this.allPeoples = [];
        this.category = [];
        this.search = null;
      }
    },
    watch: {
      search(newValue, oldValue) {
        if(newValue == '')
        {
          clearTimeout(this.searchTimer);
          this.searchCategory();
        }
      }
    },
    mounted() {
      this.$axios
        .get("category")
        .then((response) => {this.showTable = true; this.cards = response.data.data})
        .catch(e => this.toast('danger',this.$t('be-t-error-loading-category')));
    }
  }
</script>

<style>
    #addPerson,
    #removePerson,
    #card{
      cursor: pointer;
    }
    #addPerson{
      color: dodgerblue;
    }
    #removePerson{
      color: crimson;
    }
    #addPerson:hover,
    #removePerson:hover{
      box-shadow: 0px 0px 30px black;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 15px;
    }
    #cardItems{
      width: 270px;
    }
    #card{
      width: 250px;
    }
    #card:hover{
      box-shadow: 0px 20px 30px gray;
      transition: .5s;
    }
    #tableAddPerson,
    #tableRemovePerson{
      overflow: auto;
      height: 250px;
    }
</style>

