<template>
    <bui-sidebar 
        :key="$store.state.user.id"
        :menus="$store.state.user.is_admin == 1 ? menus : menusShort" 
        :variant="currentVariant"
        :menus-bottom="menus_bottom" 
        :sidebar-state="$store.state.sidebarState"
        :user="$store.state.user"
    />
</template>

<script>
export default {
    data () {
    return {
        variants: ['natural', 'primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white', 'gray'],
        currentVariant: 'secondary',
        menus: [
            {title: 'Home', icon: 'dashboard', to: '/'},
            {title: this.$t('be-peoples'), icon: 'peoples', to: '/peoples'},
            {title: this.$t('be-categories'), icon: 'gear', to: '/categories'},
            {title: this.$t('be-prices'), icon: 'quizzes', to: '/prices'},
            {title: this.$t('be-import-list'), icon: 'upload', to: '/import-list'},
            {title: this.$t('be-submit-sms'), icon: 'submit-mail', to: '/submit-sms'},
            {title: this.$t('be-relatory'), icon: 'history', to: '/historic', },
            {title: this.$t('ge-tutorials'), icon: 'play', to: '/tutorials', },
            {title: 'Admin', icon: 'user', to: '/admin', disable: true}
        ],
        menusShort: [
            {title: 'Home', icon: 'dashboard', to: '/'},
            {title: this.$t('be-peoples'), icon: 'peoples', to: '/peoples'},
            {title: this.$t('be-categories'), icon: 'gear', to: '/categories'},
            {title: this.$t('be-prices'), icon: 'quizzes', to: '/prices'},
            {title: this.$t('be-import-list'), icon: 'upload', to: '/import-list'},
            {title: this.$t('be-submit-sms'), icon: 'submit-mail', to: '/submit-sms'},
            {title: this.$t('be-relatory'), icon: 'history', to: '/historic', },
            {title: this.$t('ge-tutorials'), icon: 'play', to: '/tutorials', }
        ],
        menus_bottom: [{ title: this.$t('be-back-office'), icon: 'logo', href:'https://office.kub.app.builderall.com/us/office'}],
    }
  }
}
</script>
