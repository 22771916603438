<template>
    <div class='ba-card-breadcrumb ba-card'>
        <nav class='ba-breadcrumb'>
            <ol class='ba-breadcrumb-ol'>
                <li class='ba-breadcrumb-item' v-for="(item, k) in showBreadcrumb(items)" :key="k">
                    <b-link :to="item.href" v-html="$t(item.text)"></b-link>
                </li>
            </ol>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            items: [
                {text: "<builderall-icon code='sms' />", href: '/'},
                {text: this.$t('be-submit-sms'),         href: '/submit-sms'},
                {text: this.$t('be-sent-messages'),      href: '/historic'},
                {text: this.$t('be-peoples'),            href: '/peoples'},
                {text: this.$t('be-groups'),             href: '/categories'},
                {text: this.$t('be-pricing-table'),      href: '/prices'},
                {text: this.$t('ge-settings'),           href: '/data-user'},
                {text: this.$t('be-import-list'),        href: '/import-list'},
            ]
        }
    },
    computed: {
        hasHistory(){
            return history.length > 1
        }
    },
    methods: {
        showBreadcrumb(data){
            let newArray = [];
            let current = data.find(x => x.href == this.currentPage);
            newArray.push(data[0]);
            newArray.push(current);
            return newArray;
        },
        back() {
            history.back()
        },
        next() {
            history.forward()
        }
    }
}
</script>
